import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-formatter',
  template: '<span>{{ params }}</span>',
  styles: [
    'span{font-size: 13px; font-family: panton-bold, sans-serif; width: 100px; white-space: normal; word-break: break-word;}',
  ],
})
export class PriceFormatterComponent implements OnInit {
  params!: any;
  constructor() {}

  ngOnInit(): void {}

  agInit(params: any) {
    const value = params.getValue();
    this.params = value ? `${value.toFixed(2)}€` : 'k.A.';
  }
}
