import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  navigationTabs: string[][] = [
    // 'Dashboard',
    ['Orders', 'Bestellungen'],
    ['Swags', 'Swags'],
    ['Users', 'Nutzermanagement'],
  ];

  constructor() {}

  ngOnInit(): void {}
}
