import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/swag/category.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiConfig } from '../models/api-config.model';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  cachedCategories = new BehaviorSubject<Category[] | undefined>(undefined);
  constructor(private httpClient: HttpClient, private apiConfig: ApiConfig) {
    this.loadCategories();
  }

  loadCategories() {
    this.httpClient
      .get<Category[]>(this.apiConfig.apiGatewayUrl + '/categories')
      .pipe(
        tap((categories) => {
          if (categories.length === 0) this.cachedCategories.next(undefined);
          else this.cachedCategories.next(categories);
        }),
        take(1)
      )
      .subscribe();
  }

  createCategory(category: Category): Observable<Category> {
    //TODO: Connection to backend as soon as api is done
    const clonedCategory: Category = JSON.parse(JSON.stringify(category));
    clonedCategory.id = '35465321s65sd46a5sd653';
    return of(clonedCategory);
    // return this.httpClient.post<Category>(
    //   this.apiConfig.apiGatewayUrl + '/categories',
    //   category
    // );
  }

  setCachedCategories(categories: Category[] | undefined) {
    this.cachedCategories.next(categories);
  }
}
