import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridOptions, RowNode } from 'ag-grid-community';
import { Order, OrderStatus } from '../../shared/models/order/order.model';
import { ItemsCellRendererComponent } from './items-cell-renderer/items-cell-renderer.component';
import { TextCellRendererComponent } from './text-cell-renderer/text-cell-renderer.component';
import { OrderService } from '../order.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Subscription } from 'rxjs';
import { Modal } from '../../shared/models/modal';
import { environment } from '../../../environments/environment';
import { SwagService } from '../../swags/swag.service';
import { StatusCellRendererComponent } from './status-cell-renderer/status-cell-renderer.component';
import { UtilsService } from '../../shared/services/utils.service';
import {OrderItem} from "../../shared/models/order/order-item.model";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export interface OrderRowDataType {
  id: string;
  createdAt: string;
  name: string;
  address: string;
  orderer: string;
  items: string;
  note?: string;
  status?: OrderStatus;
  costCenter?: string;
  overallCostString?: string
}
@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit, OnDestroy {
  @ViewChild('tableGrid') tableGrid!: GridOptions;
  orders?: Order[];
  rowData: OrderRowDataType[] = [];
  loadedOrdersSub$?: Subscription;
  modal?: Modal;
  deleteOrder = environment.deleteOrder;
  rowSelected = false;

  public frameworkComponents = {
    itemsCellRenderer: ItemsCellRendererComponent,
    textCellRenderer: TextCellRendererComponent,
    statusCellRenderer: StatusCellRendererComponent,
  };

  COLUMN_DEFS = [
    { field: 'id', hide: true },
    {
      headerName: 'Datum',
      field: 'createdAt',
      sortable: true,
      filter: true,
      comparator: OrderTableComponent.dateComparator,
      sort: 'desc',
      autoHeight: true,
      cellRenderer: 'textCellRenderer',
      resizable: true,
    },
    {
      headerName: 'Empfänger',
      field: 'name',
      sortable: true,
      filter: true,
      autoHeight: true,
      cellRenderer: 'textCellRenderer',
      resizable: true,
    },
    {
      headerName: 'Adresse',
      field: 'address',
      cellRenderer: 'itemsCellRenderer',
      sortable: true,
      filter: true,
      autoHeight: true,
      resizable: true,
    },
    {
      headerName: 'Bestellt von',
      field: 'orderer',
      cellRenderer: 'textCellRenderer',
      autoHeight: true,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Bestellung',
      field: 'items',
      cellRenderer: 'itemsCellRenderer',
      autoHeight: true,
      resizable: true,
    },
    {
      headerName: 'Bemerkung',
      field: 'note',
      cellRenderer: 'textCellRenderer',
      autoHeight: true,
      resizable: true,
    },
    {
      headerName: 'Kostenstelle',
      field: 'costCenter',
      cellRenderer: 'textCellRenderer',
      autoHeight: true,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Gesamtkosten',
      field: 'overallCostString',
      cellRenderer: 'textCellRenderer',
      autoHeight: true,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusCellRenderer',
      filter: true,
      cellRendererParams: {
        statusUpdateCallback: (orderId: string, newStatus: OrderStatus) =>
          this.orderService
            .patchOrderState(orderId, newStatus)
            .subscribe(() => {
              this.rowData.find(data => data.id === orderId)!.status = newStatus;
            }),
      },
      autoHeight: true,
      resizable: true,
    }
  ];

  constructor(
    private orderService: OrderService,
    private swagService: SwagService
  ) {}

  ngOnInit(): void {}

  onGridReady() {
    this._reloadOrders();
  }

  onGridSizeChanged() {
    this.tableGrid.api?.sizeColumnsToFit();
  }

  protected _reloadOrders() {
    this.loadedOrdersSub$ = this.orderService.loadedOrders.subscribe((order) =>
      this._createRowData(order)
    );
  }

  onDelete() {
    const selectedRows: RowNode[] | undefined =
      this.tableGrid.api?.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0 || !environment.deleteOrder)
      return;

    //region modalHtml
    let selectedRowsStringRepresentation: string = '<ul>';
    selectedRows.forEach((row: RowNode) => {
      const castRow = row.data as OrderRowDataType;
      const items = castRow.items.split(',');
      let itemString = '';

      items.forEach((item: string, index) => {
        const numberPosition = index === 0 ? 0 : 1;
        itemString += numberPosition ? '&nbsp' : '';
        itemString += `<strong>${item.substr(numberPosition, 1)}</strong>`;
        const lengthSubtraction = index === 0 ? 1 : 2;
        itemString += `${item.substr(
          numberPosition + 1,
          item.length - lengthSubtraction
        )}`;
        itemString += index !== items.length - 1 ? ',' : '';
      });

      selectedRowsStringRepresentation += `<li>
        ${castRow.createdAt},
        ${castRow.name},
        ${itemString}
        </li>`;
    });
    //endregion

    selectedRowsStringRepresentation += '</ul>';

    this.modal = {
      applyButtonText: 'Löschen',
      cancelButtonText: 'Abbrechen',
      questionText: 'Sollen die folgenden Einträge wirklich gelöscht werden?',
      apply: () => this.onDeleteConfirmed(selectedRows),
      cancel: () => (this.modal = undefined),
      //region htmlCode
      htmlCode: `
        <style>
            strong, #adjust-stock-label {font-weight: bold; font-size: 14px;}
            li, label {font-size: 14px; margin-top: 8px;}
            #adjust-stock-label{margin: 0}
        </style>
        <div>
        ${selectedRowsStringRepresentation}
        </div>
        <div style="width: 100%; text-align: center; ">
            <input id="adjust-stock" type="checkbox" checked="true">
            <label id="adjust-stock-label" for="adjust-stock">Lagerbestände anpassen</label>
      </div>
    </div>`,
      //endregion
    };
  }

  onDeleteConfirmed(selectedRows: RowNode[]) {
    this.modal = undefined;

    const checkBoxStatus = (<HTMLInputElement>(
      document.getElementById('adjust-stock')!
    )).checked;

    const orderIds: string[] = [];

    selectedRows.forEach((rowNode: RowNode) => {
      const orderId: string = rowNode.data.id;
      orderIds.push(orderId);
    });

    if (checkBoxStatus) {
      this.modal = {
        apply: () => {
          this._deleteAndRestoreSwagStocks(orderIds);
          this.modal = undefined;
        },
        cancel: () => {
          this.orderService
            .batchDelete(orderIds, false)
            .subscribe(() => this.tableGrid.api?.deselectAll());
          this.modal = undefined;
        },
        applyButtonText: 'Ja',
        cancelButtonText: 'Abbrechen',
        questionText:
          'Sollen die Produkte aus den Bestellungen wirklich in den Bestand zurückgeführt werden? Die Bestellungen werden auch bei Abbruch entfernt.',
      };
    } else {
      this.orderService
        .batchDelete(orderIds, false)
        .subscribe(() => this.tableGrid.api?.deselectAll());
    }
  }

  protected _deleteAndRestoreSwagStocks(orderIds: string[]) {
    this.orderService.batchDelete(orderIds, true).subscribe(() => {
      this.tableGrid.api?.deselectAll();
      this.swagService.reloadSwags().subscribe();
    });
  }

  protected _createRowData(orders: Order[] | null): void {
    if (!orders) return;

    this.orders = orders;

    this.rowData = this.orders.map(
      (order): OrderRowDataType => ({
        id: order.id!,

        createdAt: !!order.createdAt
          ? UtilsService.formatDate(order.createdAt, 'dd.MM.yyyy - HH:mm')
          : '',

        orderer: order.orderer
          ? `${order.orderer.firstName} ${order.orderer.lastName}: ${order.orderer.email}`
          : '',

        name: `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,

        address: `${order.shippingAddress.company}, ${order.shippingAddress.street}, ${order.shippingAddress.zip} ${order.shippingAddress.city}`,

        items: order.items.reduce(function (prevVal, currVal, idx) {
          return idx == 0
            ? `${currVal.quantity} x ${currVal.variantTitle}`
            : prevVal + ', ' + `${currVal.quantity} x ${currVal.variantTitle}`;
        }, ''),

        note: order.note,
        status: order.status,

        costCenter: order.costCenter ? `${order.costCenter?.id} - ${order.costCenter?.name}` : "-",

        overallCostString: OrderTableComponent.sumUpPrices(order.items) > 0
          ? (OrderTableComponent.sumUpPrices(order.items) / 100).toFixed(2) + '€'
          : 'k.A.'
      })
  );
  }

  static sumUpPrices(orderItems: OrderItem[]): number {
    return orderItems.reduce(
      (priceInCents: number, item) => {
        return priceInCents + (item.piecePrice ? item.piecePrice * item.quantity : 0)}, 0)
  }

  static dateComparator(dateString1: string, dateString2: string): number {
    const comparableDate1: Date | null = UtilsService.stringToDate(dateString1);
    const comparableDate2: Date | null = UtilsService.stringToDate(dateString2);

    if (
      !UtilsService.isValidDate(comparableDate1) &&
      !UtilsService.isValidDate(comparableDate2)
    )
      return 0;
    if (!UtilsService.isValidDate(comparableDate1)) return -1;
    if (!UtilsService.isValidDate(comparableDate2)) return 1;

    if (comparableDate1! < comparableDate2!) {
      return -1;
    }
    if (comparableDate1! > comparableDate2!) {
      return 1;
    }

    const comparableTime1: number | null =
      UtilsService.stringToTime(dateString1);
    const comparableTime2: number | null =
      UtilsService.stringToTime(dateString2);

    if (!comparableTime1 && !comparableDate2) return 0;
    if (!comparableTime1) return -1;
    if (!comparableTime2) return 1;

    if (comparableTime1 > comparableTime2) return 1;
    if (comparableTime1 < comparableTime2) return -1;
    return 0;
  }

  onExportAsCsv() {
    const params = {
      skipHeader: true,
      skipFooters: true,
      skipGroups: true,
      suppressQuotes: true,
      columnSeparator: ';',
      onlySelected: this.rowSelected,
      processCellCallback: (param: any) => {
        switch (param.column.colId) {
          case 'note':
            return param.value
              ? param.value.replace(/(\r\n|\n|\r)/gm, ' ')
              : '-';
          default:
            return param.value;
        }
      },
      fileName: `CCSwagOrders - ${new Date().toLocaleDateString()}.csv`,
    };
    this.tableGrid.api?.exportDataAsCsv(params);
  }

  ngOnDestroy(): void {
    if (this.loadedOrdersSub$) this.loadedOrdersSub$?.unsubscribe();
  }

  onRowSelection() {
    const rows = this.tableGrid.api?.getSelectedNodes();
    this.rowSelected = !!rows ? rows?.length > 0 : false;
  }
}
