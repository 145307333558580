<div class="outer-container">
  <form [formGroup]="editForm">
    <div
      [ngClass]="isPackage ? 'package-edit-container' : 'swag-edit-container'"
    >
      <div class="general-container">
        <div class="title-container">
          <label for="title">Name:</label>
          <input id="title" formControlName="title" />
        </div>
        <div class="category-container">
          <label for="category">Kategorie:</label>
          <select id="category" formControlName="category">
            <option [selected]="!selectedCategory">
              {{ noCategorySelectedText }}
            </option>
            <option *ngIf="categoryCreation">{{ addNewCategoryText }}</option>
            <option
              [selected]="
                selectedCategory
                  ? selectedCategory.name === category.name
                  : false
              "
              *ngFor="let category of categories"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="price-container">
          <label for="price">Preis:</label>
          <input
            id="price"
            formControlName="price"
            type="number"
            step="0.01"
            min="0"
            placeholder="0,00"
          />
        </div>

        <div class="image-container">
          <div id="image-label-container">
            <label for="image">Bild:</label>
          </div>
          <div
            id="image"
            appDragAndDrop
            [class.invalid-image]="!imageValid"
            (onFileDrop)="onChangeImage($event)"
            (click)="uploadInput.click()"
          >
            <span>Bild hier ablegen oder klicken</span>
          </div>
        </div>
      </div>

      <div class="variants-header">
        <h4>Ausgewählte Variante</h4>
      </div>

      <div class="variant-container">
        <div class="variant-title-container">
          <label for="variant-title">Titel:</label>
          <input id="variant-title" formControlName="variantTitle" />
        </div>

        <div class="variant-option-container">
          <label for="option" id="option-label">Label:</label>
          <input id="option" formControlName="variantOptions" />
          <label for="quantity" id="quantity-label">Bestand:</label>
          <input
            [attr.disabled]="isPackage ? true : null"
            id="quantity"
            type="number"
            min="0"
            formControlName="variantQuantity"
          />
        </div>
      </div>
      <div class="button-container">
        <button
          class="primary-button"
          (click)="onAddVariant()"
          [disabled]="!variantAddable"
        >
          Variante hinzufügen
        </button>
        <button
          class="secondary-button"
          [disabled]="!chosenVariant"
          (click)="onDeleteVariant()"
        >
          Variante löschen
        </button>
      </div>
      <div *ngIf="isPackage" class="table-container">
        <h4>Paket Inhalt</h4>
        <app-swag-table
          (addSwagRequest)="onRequestAddSwag()"
          (gridValuesChanged)="formValid()"
          id="table-component"
          [tableMode]="variantTableMode"
          [gridGroup]="gridGroup"
          [chosenSwagIds]="packagedSwagIds!"
          (removePackageSwagIds)="onRemoveSwagsFromPackage($event)"
        ></app-swag-table>
      </div>
    </div>
  </form>
</div>
<input
  hidden
  type="file"
  accept="image/*"
  #uploadInput
  (change)="onChangeImageFromSelection($event)"
/>
<div *ngIf="addSwagDialogueOpen" class="modal-table">
  <div class="modal-table-content">
    <h4>Wähle die Swags aus, die du dem Paket hinzufügen möchtest.</h4>
    <app-swag-table
      [tableMode]="swagTableMode"
      [chosenSwagIds]="packagedSwagIds!"
      [selectedSwag]="loadedSwag!"
      (cancelSwagRequest)="this.addSwagDialogueOpen = false"
      (selectPackageSwagIds)="onAddSwagsToPackage($event)"
    ></app-swag-table>
  </div>
</div>
<app-modal [modal]="modal" *ngIf="modal"> </app-modal>
