import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { UserService } from '../shared/services/user.service';
import { tap } from 'rxjs/operators';
import { UserRole } from '../shared/models/user/user-role.model';
import { UserRoleRendererComponent } from './user-role-renderer.component';
import { User } from '../shared/models/user/user.model';

export interface UserRowDataType {
  user: User;
  fullName: string;
  email: string;
  userRoles: UserRole[];
}

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
})
export class UsersManagementComponent implements OnInit {
  @ViewChild('tableGrid') tableGrid?: GridOptions;
  COLUMN_DEFS: ColDef[] = [
    { field: 'user', hide: true },
    {
      field: 'fullName',
      headerName: 'Name',
      resizable: true,
      filter: true,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      resizable: true,
      filter: true,
      sortable: true,
    },
    {
      field: 'userRoles',
      headerName: 'Berechtigungen',
      resizable: true,
      filter: true,
      sortable: true,
      cellRendererFramework: UserRoleRendererComponent,
      valueFormatter: (params: any) => {
        const userRoles = params.data.userRoles;
        return userRoles.toString();
      },
    },
  ];
  rowData: any;
  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  onGridReady() {
    this.reloadUsers();
  }

  reloadUsers() {
    const loggedInUser = this.userService.cachedUser;
    this.userService
      .getUsers()
      .pipe(
        tap((users) => {
          const userRowDataTypes: UserRowDataType[] = [];
          users.forEach((user) => {
            if (user.id !== loggedInUser?.id) {
              userRowDataTypes.push({
                user: user,
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
                userRoles: user.userRoles,
              });
            }
          });
          this.rowData = userRowDataTypes;
        })
      )
      .subscribe();
  }
}
