import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SwagService } from '../swag.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanActivateEditGuardService implements CanActivate {
  constructor(private swagService: SwagService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let foundId: string | null;

    if (route.parent) {
      const cardRoute = route.parent?.children.filter(
        (child) => child.outlet === 'card'
      );

      if (cardRoute.length > 0) {
        foundId = cardRoute[0].paramMap.get('id');

        if (foundId) {
          return this.swagService.getSwagById(foundId!).pipe(
            map((swag) => {
              if (!swag) return this.routeToSwagTable();
              return true;
            })
          );
        }
      }
    }

    return this.routeToSwagTable();
  }

  private routeToSwagTable() {
    return this.router.parseUrl('/swags');
  }
}
