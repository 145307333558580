import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '../models/api-config.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private httpClient: HttpClient, private apiConfig: ApiConfig) {}

  uploadFile(file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(this.apiConfig.apiURL + '/files', formData, {
      responseType: 'text',
    });
  }

  static base64ToFile(base64: string, newImageName: string): File {
    const fileType = base64.match(/data:(.*?);/)![1];
    const cleanedBase64 = base64.replace(/.*,/, '');

    const byteString = window.atob(cleanedBase64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: fileType });

    return new File([blob], newImageName);
  }
}
