import { Component, OnDestroy, OnInit } from '@angular/core';
import { Swag } from '../../shared/models/swag/swag.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SwagService } from '../swag.service';
import { Variant } from '../../shared/models/swag/variant.model';
import { SwagEditService } from '../swag-edit/swag-edit.service';
import { skip, take } from 'rxjs/operators';
import { Modal } from '../../shared/models/modal';

@Component({
  selector: 'app-card-view',
  templateUrl: './swag-card.component.html',
  styleUrls: ['./swag-card.component.scss'],
})
export class SwagCardComponent implements OnInit, OnDestroy {
  id?: string;
  defaultSwagShown: boolean = true;
  loadedCard: Swag = this.swagService.defaultSwag;
  editMode = false;
  selectedVariant: Variant | null = null;
  swagHasOptionValues: boolean = false;
  modal?: Modal;
  validForm: boolean = true;
  variants: Variant[] | undefined;

  editModeSub$ = this.swagEditService.editMode.subscribe(
    (editMode: boolean) => {
      this.editMode = editMode;
    }
  );

  selectedVariantSub$ = this.swagEditService.selectedVariant.subscribe(
    (selectedVariant) => {
      this.selectedVariant = selectedVariant;
    }
  );

  variantUpdated = this.swagEditService.variantUpdated.subscribe(() =>
    this.swagVariantsHaveOptionValues()
  );
  validFormSub$ = this.swagEditService.validForm.subscribe((valid) => {
    this.validForm = valid;
  });

  loadedSwagsUpdatedSub$?: Subscription;
  idSub$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private swagService: SwagService,
    private swagEditService: SwagEditService
  ) {}

  ngOnInit(): void {
    this.idSub$ = this.route.paramMap.subscribe((map: ParamMap) => {
      this._assignId(map.get('id'));
      this._reloadShownSwag();
    });

    //If swags in SwagService update, get same object that is in the loadedSwags array.
    this.loadedSwagsUpdatedSub$ = this.swagService.loadedSwags
      .pipe(skip(1))
      .subscribe(() => {
        this._reloadShownSwag();
      });

    this.swagEditService.cancelEditing.subscribe(() => this._closeEdit());
  }

  swagVariantsHaveOptionValues() {
    this.swagHasOptionValues = false;

    if (this.loadedCard.variants.length > 1) {
      this.swagHasOptionValues = true;
      return;
    }

    if (this.loadedCard.variants.length === 1)
      this.swagHasOptionValues = !!this.loadedCard.variants[0].label;
  }

  onSelectCard() {
    if (this.defaultSwagShown) return;
    if (SwagService.isPackage(this.loadedCard)) this.onEdit(true);
    else this.onEdit(false);
  }

  onEdit(isPackage: boolean) {
    if (this.defaultSwagShown) return;
    if (isPackage) this.router.navigate(['/swags/package/edit']);
    else this.router.navigate(['/swags/edit']);
  }

  onSave() {
    if (this.id) this.swagEditService.emitSaveSwag();
  }

  onClose() {
    this.swagService.emitOnSwagCardClosed();
    this.router.navigate(['swags']);
  }

  onCancel() {
    if (this.swagEditService.addingSwag) this.id = undefined;
    this.swagEditService.emitCancelEditing();
  }

  //Navigate back to swag table while keeping card loaded
  protected _closeEdit() {
    const url = this.id ? `/swags/(card:${this.id})` : `swags`;
    this.router.navigateByUrl(url);
  }

  onChooseVariant(variant: Variant, event: MouseEvent) {
    if (this.editMode) event.stopPropagation();
    if (!this.defaultSwagShown) {
      this.swagEditService.setProposeSelectedVariant(variant);
    }
  }

  onAdd() {
    const newLocalSwag = this.swagService.addLocalSwag();

    if (!newLocalSwag) return;
    this.defaultSwagShown = false;

    const urlAdjustmentCode = (swagType: string) => {
      this.modal = undefined;
      const editCode =
        swagType === 'swag'
          ? () => this.onEdit(false)
          : () => this.onEdit(true);
      if (this.id) {
        this.router
          .navigateByUrl(this.router.url.replace(this.id!, newLocalSwag.id!))
          .then(editCode);
      } else {
        this.router
          .navigate(['/swags', { outlets: { card: [newLocalSwag.id!] } }])
          .then(editCode);
      }
    };

    this.modal = {
      questionText: 'Soll ein normaler Swag oder ein Paket erstellt werden?',
      apply: () => urlAdjustmentCode('swag'),
      applyButtonText: 'Swag',
      cancel: () => urlAdjustmentCode('package'),
      cancelButtonText: 'Paket',
    };
  }

  onDeleteRequest() {
    this.modal = {
      questionText: 'Soll dieser Swag wirklich gelöscht werden?',
      applyButtonText: 'Löschen',
      cancelButtonText: 'Abbrechen',
      apply: () => this.onApplyModal(true),
      cancel: () => this.onApplyModal(false),
    };
  }

  protected _reloadShownSwag() {
    if (this.id) {
      this.swagService
        .getSwagById(this.id)
        .pipe(take(1))
        .subscribe((swag) => {
          //Should never be false because of the can-activate-card guard
          if (swag) {
            this.loadedCard = swag;
            this.variants = this.loadedCard.variants;
            this.swagService.selectedSwag = swag;
            this.defaultSwagShown = false;
            this.swagVariantsHaveOptionValues();
          }
        });
      return;
    }

    this.loadedCard = this.swagService.defaultSwag;
    this.defaultSwagShown = true;
    this.swagVariantsHaveOptionValues();
  }

  protected _assignId(id: string | null) {
    this.id = id ? id : undefined;
  }

  onApplyModal(event: boolean) {
    this.modal = undefined;
    if (event) this.onDeleteSwag();
  }

  onDeleteSwag() {
    this.swagService
      .deleteSwag(this.id!)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.router.navigate(['/swags']);
        },
        (error) =>
          (this.modal = {
            questionText: error.error.error,
            apply: () => {
              this.swagEditService.emitCancelEditing();
              this.modal = undefined;
            },
            applyButtonText: 'Okay',
          })
      );
  }

  inStock(variant: Variant): string {
    const inventoryQuantity = variant.quantity;
    if (
      inventoryQuantity === null ||
      isNaN(inventoryQuantity) ||
      inventoryQuantity.toLocaleString() === '' ||
      inventoryQuantity < 0
    )
      return 'invalid';
    return inventoryQuantity > 0 ? 'in-stock' : 'out-of-stock';
  }

  loadedCardIsPackage(): boolean {
    return SwagService.isPackage(this.loadedCard);
  }

  ngOnDestroy() {
    this.idSub$?.unsubscribe();
    this.editModeSub$.unsubscribe();
    this.selectedVariantSub$.unsubscribe();
    this.variantUpdated.unsubscribe();
    this.loadedSwagsUpdatedSub$?.unsubscribe();
    this.validFormSub$.unsubscribe();
  }
}
