import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { OrderStatus } from '../../../shared/models/order/order.model';

interface customParams extends ICellRendererParams {
  statusUpdateCallback: (orderId: string, newStatus: OrderStatus) => void;
}

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent {
  stateValues = Object.values(OrderStatus);
  updateCallback?: (orderId: string, orderStatus: OrderStatus) => void;
  statusString?: string;
  orderId?: string;
  params?: customParams;

  agInit(params: customParams): void {
    this.orderId = params.data.id;
    this.updateCallback = params.statusUpdateCallback;
    this.params = params;

    if (params.data.status) this.statusString = params.data.status.toString();
  }

  updateStatus(value: any) {
    this.updateCallback!(this.orderId!, value.value);
  }
}
