import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiConfig } from '../models/api-config.model';
import { User } from '../models/user/user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  cachedUser?: User;

  constructor(private httpClient: HttpClient, private apiConfig: ApiConfig) {}

  getUser(): Observable<User> {
    //If cached user set, return it as observable
    if (this.cachedUser) {
      return of(this.cachedUser);
    }

    //Get user from api, set cached user to result, return result of request
    return this.httpClient
      .get<User>(this.apiConfig.apiGatewayUrl + '/users/currentUser')
      .pipe(
        map((user) => {
          this.cachedUser = user;
          return user;
        })
      );
  }

  getUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.apiConfig.apiGatewayUrl + '/users');
  }

  updateUser(user: User): Observable<void> {
    return this.httpClient.patch<void>(
      this.apiConfig.apiGatewayUrl + '/users/' + user.id,
      user
    );
  }
}
