import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  //string with format dd/mm/yyyy
  static stringToDate = (input: string): Date | null => {
    let parts = input.match(/(\d+)/g);
    if (!parts || !parts[0] || !parts[1] || !parts[2]) return null;
    return new Date(+parts![2], +parts![1] - 1, +parts![0]);
  };

  static isValidDate = (date: Date | null) => {
    return date instanceof Date && !isNaN(date.valueOf());
  };

  //String with format of decimals/decimals/decimals/hh/mm
  static stringToTime = (input: string): number | null => {
    let parts = input.match(/(\d+)/g);
    if (!parts || !parts[3] || !parts[4]) return null;
    return +parts[3] * 60 + +parts[4];
  };

  static formatDate(isoDate: string, format: string): string {
    const datePipe = new DatePipe('de_DE');
    return datePipe.transform(isoDate, format)!;
  }
}
