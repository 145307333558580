import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { FormGroup, Validators } from '@angular/forms';
import { EditSwagValidatorsDirective } from '../../swag-edit/validators/edit-swag-validators.directive';
import { SwagService } from '../../swag.service';
import { PackagedSwag } from '../../../shared/models/swag/swag.model';

interface CustomParams extends ICellRendererParams {
  notifierCallback: () => void;
}
@Component({
  selector: 'app-quantity-select-renderer',
  template: ` <div *ngIf="form" [formGroup]="form!">
    <input
      [formControlName]="key!"
      type="number"
      min="1"
      [(ngModel)]="quantity"
      (change)="updateQuantity()"
    />
  </div>`,
  styles: [
    `
      input {
        width: 70px;
        font-size: 13px;
        background-color: transparent;
        border-width: 1px;
      }
      input.ng-invalid {
        border: 2px solid red;
      }
    `,
  ],
})
export class QuantitySelectRendererComponent implements OnInit {
  quantity = 0;
  key?: string;
  form?: FormGroup;
  notifierCallback?: () => void;
  validators = new EditSwagValidatorsDirective();
  packagedSwagEntry?: PackagedSwag;

  constructor(private swagService: SwagService) {}

  ngOnInit(): void {}

  agInit(params: CustomParams) {
    this.key = params.context.createKey(params.node, params.column);
    this.notifierCallback = params.notifierCallback;
    this.form = params.context.formGroup;
    this.form!.get(this.key!)!.addValidators([
      Validators.required,
      Validators.min(1),
      this.validators.variantQuantityValidator,
    ]);

    this.packagedSwagEntry = this.swagService.selectedSwag?.packagedSwags?.find(
      (packagedSwag) => packagedSwag.swagId === params.data.id
    );

    this.quantity = this.packagedSwagEntry?.quantity!;
    this.updateQuantity();
  }

  updateQuantity() {
    if (this.form) {
      this.form.controls[this.key!].patchValue(this.quantity);
      this.packagedSwagEntry!.quantity = this.quantity;
      this.notifierCallback!();
    }
  }
}
