import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './header/navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { SwagsComponent } from './swags/swags.component';
import { ImageFormatterComponent } from './swags/swag-table/formatters/image-formatter/image-formatter.component';
import { SwagTableComponent } from './swags/swag-table/swag-table.component';
import { SwagCardComponent } from './swags/card/swag-card.component';
import { ViewVariantsFormatterComponent } from './swags/swag-table/formatters/variants-formatters/view-variants-formatter.component';
import { SwagEditComponent } from './swags/swag-edit/swag-edit.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthIdTokenInterceptor } from './shared/interceptors/oauth-id-token.interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ApiConfig } from './shared/models/api-config.model';
import { environment } from '../environments/environment';
import { TextFormatterComponent } from './swags/swag-table/formatters/text-formatter/text-formatter.component';
import { CategoryFormatterComponent } from './swags/swag-table/formatters/category-formatter/category-formatter.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { DragAndDropDirective } from './shared/directives/drag-and-drop.directive';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { EditSwagValidatorsDirective } from './swags/swag-edit/validators/edit-swag-validators.directive';
import { OrderTableComponent } from './orders/order-table/order-table.component';
import { ItemsCellRendererComponent } from './orders/order-table/items-cell-renderer/items-cell-renderer.component';
import { TextCellRendererComponent } from './orders/order-table/text-cell-renderer/text-cell-renderer.component';
import { StatusCellRendererComponent } from './orders/order-table/status-cell-renderer/status-cell-renderer.component';
import { SelectVariantsFormatterComponent } from './swags/swag-table/formatters/variants-formatters/select-variants-formatter.component';
import { QuantitySelectRendererComponent } from './swags/swag-table/formatters/quantity-select-renderer.component';
import { UsersManagementComponent } from './users/users-management.component';
import { UserRoleRendererComponent } from './users/user-role-renderer.component';
import {PriceFormatterComponent} from "./swags/swag-table/formatters/price-formatter/price-formatter.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    DashboardComponent,
    OrdersComponent,
    SwagsComponent,
    ImageFormatterComponent,
    SwagTableComponent,
    SwagCardComponent,
    ViewVariantsFormatterComponent,
    SwagEditComponent,
    TextFormatterComponent,
    CategoryFormatterComponent,
    PriceFormatterComponent,
    ModalComponent,
    DragAndDropDirective,
    UnauthorizedComponent,
    EditSwagValidatorsDirective,
    OrderTableComponent,
    ItemsCellRendererComponent,
    TextCellRendererComponent,
    StatusCellRendererComponent,
    SelectVariantsFormatterComponent,
    QuantitySelectRendererComponent,
    UsersManagementComponent,
    UserRoleRendererComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgGridModule.withComponents([
      ImageFormatterComponent,
      ViewVariantsFormatterComponent,
      TextFormatterComponent,
      CategoryFormatterComponent,
      ItemsCellRendererComponent,
      TextCellRendererComponent,
    ]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: ApiConfig, useValue: environment.apiConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthIdTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
