import { Directive } from '@angular/core';
import { Swag } from '../../../shared/models/swag/swag.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SwagService } from '../../swag.service';

@Directive({
  selector: '[appEditSwagValidators]',
})
export class EditSwagValidatorsDirective {
  loadedSwag: Swag | null = null;

  constructor() {}

  variantLabelValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.loadedSwag) return null;
      const label = control.value;
      const valid =
        this.loadedSwag.variants.length === 1 || (label && label.length > 0);
      return valid ? null : { noLabel: { value: label } };
    };
  }

  variantQuantityValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isNaN(control.value)
        ? { quantityNotANumber: { value: control.value } }
        : null;
    };
  }

  variantSelectionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !control.value
        ? { noVariantSelected: { value: control.value } }
        : null;
    };
  }

  priceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      const numberString = control.value.toString();
      const match = numberString.match(/[^0-9](.*)/);

      if (!match) return null;

      return match[1].length > 2
        ? { invalidPrice: { value: control.value } }
        : null;
    };
  }

  packagedSwagsGroupValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (SwagService.isPackage(this.loadedSwag!)) return null;
      return { packagedSwagsMustContainASwag: { value: control.value! } };
    };
  }
}
