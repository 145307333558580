import { Injectable } from '@angular/core';
import { Swag } from '../shared/models/swag/swag.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '../shared/models/api-config.model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { FileService } from '../shared/services/file.service';
import { CategoryService } from '../shared/services/category.service';
import { SwagEditService } from './swag-edit/swag-edit.service';

@Injectable({
  providedIn: 'root',
})
export class SwagService {
  onSwagCardClosed = new Subject();
  loadedSwags = new BehaviorSubject<{ [id: string]: Swag } | null>(null);
  defaultImage: string = '../../assets/images/DefaultPicture.png';
  defaultImageName: string = 'unnamedImage';
  selectedSwag?: Swag;
  basePath = this.apiConfig.apiGatewayUrl;

  defaultSwag: Swag = {
    id: 'default-swag',
    title: 'Click a swag to show its card',
    variants: [
      {
        title: 'Default swag',
        quantity: 2,
        label: undefined,
      },
    ],
    categories: [],
    image: this.defaultImage,
    price: undefined,
  };

  //Inject CategoryService to preload categories for usage in edit
  constructor(
    private httpClient: HttpClient,
    private apiConfig: ApiConfig,
    private fileService: FileService,
    private swagEditService: SwagEditService,
    private _: CategoryService
  ) {
    this.reloadSwags().pipe(take(1)).subscribe();
  }

  getSwagById(swagId: string): Observable<Swag | null> {
    const getByIdCode = () => {
      const value = this.loadedSwags.getValue();
      if (value) return of(value[swagId]);
      return of(null);
    };
    if (!this.loadedSwags.getValue()) {
      return this.reloadSwags().pipe(take(1), switchMap(getByIdCode));
    }

    return getByIdCode();
  }

  getSwagsByIds(swagIds: string[]): Observable<Swag[]> {
    let swags = this.loadedSwags.getValue();

    const getSwagsByIdsCode = (): Observable<Swag[]> => {
      const foundSwags: Swag[] = [];
      if (!swags) return of([]);
      swagIds.forEach((swagId) => {
        const foundSwag = swags![swagId];
        if (foundSwag) foundSwags.push(swags![swagId]);
      });
      return of(foundSwags);
    };

    if (!swags) {
      return this.reloadSwags().pipe(
        take(1),
        switchMap((returnedSwags) => {
          swags = returnedSwags;
          return getSwagsByIdsCode();
        })
      );
    }

    return getSwagsByIdsCode();
  }

  reloadSwags(): Observable<{ [id: string]: Swag } | null> {
    return this.httpClient.get<Swag[]>(this.basePath + '/swags').pipe(
      map((swags) => {
        if (!swags) return null;
        let swagsArray: { [id: string]: Swag } = {};

        swags.forEach((swag) => {
          let swagId = swag.id!;
          swagsArray[swagId] = this.convertSwag(swag);
        });
        return swagsArray;
      }),
      tap((swagsArray) => {
        this.loadedSwags.next(swagsArray);
      })
    );
  }

  updateSwag(swagId: string): Observable<Swag | null> {
    return this.getSwagById(swagId).pipe(
      take(1),
      switchMap((swag) => {
        if (!swag) return of(null);
        const requestCode = () => {
          const clonedSwag = JSON.parse(JSON.stringify(swag));
          clonedSwag.price *= 100;
          clonedSwag.image = SwagService.disassembleImagePath(swag.image);

          console.log('clonedSwag: ', clonedSwag);

          if (this.swagEditService.addingSwag)
            return this.createSwag(clonedSwag);

          return this.httpClient.put<Swag>(
            this.basePath + `/swags/${swagId}`,
            clonedSwag
          );
        };

        if (this.swagEditService.imageEdited) {
          const imageName = !!this.swagEditService.newImageName
            ? this.swagEditService.newImageName
            : this.defaultImageName;

          const file = FileService.base64ToFile(swag.image, imageName);

          return this.fileService.uploadFile(file).pipe(
            take(1),
            switchMap((imageName) => {
              this.swagEditService.imageEdited = false;
              swag.image = this.assembleImagePath(imageName);
              return requestCode();
            })
          );
        } else {
          return requestCode();
        }
      })
    );
  }
  updateAndReloadSwag(swagId: string): Observable<Swag | null> {
    return this.updateSwag(swagId).pipe(
      tap((swag) => {
        if (swag) this.reloadSwags().pipe(take(1)).subscribe();
      })
    );
  }

  convertSwag(swag: Swag): Swag {
    return {
      id: swag.id,
      title: swag.title,
      variants: swag.variants,
      image: this.assembleImagePath(swag.image),
      categories: swag.categories,
      packagedSwags: swag.packagedSwags,
      price: swag.price ? swag.price / 100 : undefined,
    };
  }

  addLocalSwag(): Swag | null {
    const newSwag: Swag = {
      id: this.swagEditService.addedSwagId,
      title: '',
      price: undefined,
      variants: [
        {
          title: '',
          quantity: 0,
          label: undefined,
        },
      ],
      categories: [],
      image: this.defaultImage,
      packagedSwags: [],
    };

    let swagsArray = this.loadedSwags.getValue();
    if (!swagsArray) swagsArray = {};
    else if (swagsArray[this.swagEditService.addedSwagId]) return null;
    swagsArray[newSwag.id!] = this.convertSwag(newSwag);
    swagsArray[newSwag.id!].image = SwagService.disassembleImagePath(
      swagsArray[newSwag.id!].image
    );

    this.swagEditService.addingSwag = true;
    this.loadedSwags.next(swagsArray);
    return swagsArray[newSwag.id!];
  }

  createSwag(localSwag: Swag): Observable<Swag | null> {
    if (!this.swagEditService.addingSwag) return of(null);

    let swagsArray = this.loadedSwags.getValue();
    if (!swagsArray) swagsArray = {};
    if (swagsArray[localSwag.id!]) delete swagsArray[localSwag.id!];

    localSwag.id = undefined;
    return this.httpClient.post<Swag>(this.basePath + '/swags', localSwag).pipe(
      map((swag) => this.convertSwag(swag)),
      tap((swag) => {
        let swags: { [id: string]: Swag } | null = this.loadedSwags.getValue();
        if (!swags) swags = {};
        if (swag.id) {
          swags[swag.id] = swag;
        }
        this.loadedSwags.next(swags);
        this.swagEditService.addingSwag = false;
        this.selectedSwag = swag;
      })
    );
  }

  deleteSwag(swagId: string): Observable<undefined> {
    if (this.swagEditService.addingSwag) {
      console.log('in');
      const swagArray = this.loadedSwags.getValue()!;
      delete swagArray[this.swagEditService.addedSwagId];
      this.loadedSwags.next(swagArray);
      this.swagEditService.addingSwag = false;

      return of(undefined);
    }
    return this.httpClient
      .delete<void>(this.basePath + `/swags/${swagId}`)
      .pipe(
        tap(() => this.reloadSwags().pipe(take(1)).subscribe()),
        map(() => undefined)
      );
  }

  assembleImagePath(image: string): string {
    if (image.match(this.apiConfig.apiURL + '/files/')) return image;
    return this.apiConfig.apiURL + '/files/' + image;
  }

  static disassembleImagePath(image: string): string {
    return image.replace(/.*\/files\//, '');
  }

  emitOnSwagCardClosed() {
    this.onSwagCardClosed.next();
  }

  public static isPackage(swag: Swag): boolean {
    return !!swag.packagedSwags && swag.packagedSwags.length > 0;
  }
}
