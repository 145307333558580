import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Variant } from '../../shared/models/swag/variant.model';

@Injectable({
  providedIn: 'root',
})
export class SwagEditService {
  selectedVariant = new BehaviorSubject<Variant | null>(null);
  proposeSelectedVariant = new BehaviorSubject<Variant | null>(null);
  editMode = new BehaviorSubject<boolean>(false);
  validForm = new BehaviorSubject<boolean>(true);
  cancelEditing = new Subject();
  variantUpdated = new Subject();
  saveSwag = new Subject();
  addingSwag: boolean = false;
  readonly addedSwagId: string = 'addedSwag';
  newImageName?: string;
  imageEdited = false;

  setSelectedVariant(variant: Variant | null) {
    this.selectedVariant.next(variant);
  }
  setProposeSelectedVariant(variant: Variant | null) {
    this.proposeSelectedVariant.next(variant);
  }

  setEditMode(editMode: boolean) {
    this.editMode.next(editMode);
  }

  setValidForm(valid: boolean) {
    this.validForm.next(valid);
  }

  emitCancelEditing() {
    this.cancelEditing.next();
  }

  emitSaveSwag() {
    this.saveSwag.next();
  }

  emitVariantUpdated() {
    this.variantUpdated.next();
  }
}
