<div class="container">
  <div class="sticky-container">
    <div
      class="card-container"
      (click)="onSelectCard()"
      [class.unselectable]="defaultSwagShown || editMode"
    >
      <img [src]="loadedCard.image"
           [alt]="loadedCard.title"
           class="swag-image"/>
      <span
        class="title"
        [class.title-with-badge]="!swagHasOptionValues"
      >{{ loadedCard.title }}
        <span class="badge"
              [ngClass]="inStock(loadedCard.variants[0])"
              *ngIf="!swagHasOptionValues">{{
          inStock(loadedCard.variants[0]) === 'in-stock' ? loadedCard.variants[0].quantity : 'X'
          }}</span>
    </span>

      <div class="options-container" *ngIf="swagHasOptionValues">
        <div *ngFor="let variant of loadedCard.variants" class="values-container">
        <span
          class="value-box"
          [class.invalid-variant]="loadedCard.packagedSwags && variant.packagedVariantIds!.length !== loadedCard.packagedSwags.length"
          (click)="onChooseVariant(variant, $event)"
          [class.active]="variant === selectedVariant"
        >
          {{ variant.label}}
          <span class="badge" [ngClass]="inStock(variant)">
            {{ inStock(variant) === 'in-stock' ? variant.quantity : 'X' }}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button
        class="left-button primary-button"
        [disabled]="defaultSwagShown || (!validForm && editMode)"
        (click)="editMode ? onSave() : onEdit(loadedCardIsPackage())"
      >
        {{ editMode ? "Speichern" : "Bearbeiten" }}
      </button>
      <button
        class="right-button"
        [ngClass]="editMode ? 'secondary-button' : 'primary-button'"
        (click)="editMode ? onDeleteRequest() : onAdd()"
      >
        {{ editMode ? "Löschen" : "Erstellen" }}
      </button>
      <button
        class="close-button secondary-button"
        [disabled]="defaultSwagShown"
        (click)="editMode ? onCancel() : onClose()"
      >
        {{ editMode ? "Abbrechen" : "Schließen" }}
      </button>
    </div>
  </div>
</div>

<app-modal [modal]="modal"
  *ngIf="modal">
</app-modal>
