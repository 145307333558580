import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cc-text-cell-renderer',
  templateUrl: './text-cell-renderer.component.html',
  styleUrls: ['./text-cell-renderer.component.scss'],
})
export class TextCellRendererComponent {
  text?: string;

  agInit(params: ICellRendererParams): void {
    this.text = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.text = params.value;
  }
}
