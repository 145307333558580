import { Variant } from '../../../shared/models/swag/variant.model';
import { OptionQuantity } from './variants-formatters/view-variants-formatter.component';

export class FormatterMethods {
  static variantsFormatter(variants: Variant[]): OptionQuantity[] {
    const options: OptionQuantity[] = [];

    variants.forEach((variant) => {
      let title = variant.label!;
      let quantity = ' (' + variant.quantity + ')';

      options.push({ title: title, quantity: quantity });
    });

    return options;
  }
}
