import { OrderItem } from './order-item.model';
import { User } from '../user/user.model';
import { Address } from '../address/address.model';
import { Costcenter } from '../costcenter/costcenter.model';

export interface Order {
  id?: string;
  userId?: string;
  items: OrderItem[];
  createdAt?: string;
  note?: string;
  shippingAddress: Address;
  orderer?: User;
  status?: OrderStatus;
  costCenter?: Costcenter;
}

export enum OrderStatus {
  UNPROCESSED = 'Unbearbeitet',
  SENT = 'Versendet',
}
