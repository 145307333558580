import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OAuthIdTokenInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private authService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      environment.authentication.active === 'true' &&
      this.authService.discoveryDocument &&
      request.url !== this.authService.discoveryDocument.jwks_uri &&
      this.authStorage.getItem('id_token')
    ) {
      const token = this.authStorage.getItem('id_token');
      const header = 'Bearer ' + token;
      const headers = request.headers.set('Authorization', header);

      request = request.clone({ headers });
    }
    return next.handle(request).pipe(catchError((err) => throwError(err)));
  }

  wait(ms: number) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
}
