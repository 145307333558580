<div class="outer-container">
  <div class="inner-container">
    <ul class="nav-list">
      <li class="nav-list-item" *ngFor="let navTab of navigationTabs">
        <a
          style="cursor: pointer"
          routerLinkActive="active-nav-item"
          [routerLink]="navTab[0].toLowerCase()"
        >
          {{ navTab[1] }}
        </a>
      </li>
    </ul>
  </div>
</div>
