import { Component, OnInit } from '@angular/core';
import { Variant } from '../../../../shared/models/swag/variant.model';
import { FormatterMethods } from '../FormatterMethods';

export interface OptionQuantity {
  title: string;
  quantity: string;
}

@Component({
  selector: 'app-array-text-formatter',
  template:
    '<span *ngFor="let option of options, last as isLast"> <strong style="font-family: panton-bold, sans-serif">{{ option.title }}</strong> {{ option.quantity }}{{ isLast ? "" : ", " }}</span>',
  styles: ['span, strong { padding: 0; margin: 0; font-size: 13px;}'],
})
export class ViewVariantsFormatterComponent implements OnInit {
  // params!: string[];
  options: OptionQuantity[] = [];

  constructor() {}

  ngOnInit(): void {}

  agInit(params: any) {
    const variants = <Variant[]>params.getValue();
    this.options = FormatterMethods.variantsFormatter(variants);
  }
}
