import { Component } from '@angular/core';

@Component({
  selector: 'app-image-formatter',
  template:
    '<div><img src="{{ params.value }}" alt="{{ params.value }}"></div>',
  styles: [
    'div {display: flex; justify-content: center; width: 99px;}',
    'img {max-width: 99px; max-height: 99px; padding-top: 3px; padding-bottom: 3px; }',
  ],
})
export class ImageFormatterComponent {
  params: any;

  agInit(params: any) {
    this.params = params;
  }
}
