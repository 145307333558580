import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public discoveryDocument: any;

  public constructor(private oAuthService: OAuthService) {
    if (environment.authentication.active === 'true') {
      this.configureAuthentication();
    }
  }

  public loadDiscoveryDocument(): Observable<any> {
    return from(
      this.oAuthService.loadDiscoveryDocument(
        environment.authentication.openIdConfigUrl
      )
    ).pipe(
      map(
        (successEvent) =>
          (this.discoveryDocument = (
            successEvent as any
          ).info.discoveryDocument)
      )
    );
  }

  /* istanbul ignore next */
  public tryLogin(): Observable<boolean> {
    return from(this.oAuthService.tryLogin());
  }

  /* istanbul ignore next */
  public logOut() {
    this.oAuthService.logOut();
  }

  /* istanbul ignore next */
  public hasValidIdToken(): boolean {
    return this.oAuthService.hasValidIdToken();
  }

  /* istanbul ignore next */
  public setupAutomaticSilentRefresh(): void {
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  /* istanbul ignore next */
  public initImplicitFlow(): void {
    this.oAuthService.initImplicitFlow();
  }

  /* istanbul ignore next */
  public get idToken(): string {
    return this.oAuthService.getIdToken();
  }

  /* istanbul ignore next */
  public get redirectUri(): string | undefined {
    return this.oAuthService.redirectUri;
  }

  /* istanbul ignore next */
  public set redirectUri(redirectUri: string | undefined) {
    this.oAuthService.redirectUri = redirectUri;
  }

  private configureAuthentication(): void {
    this.oAuthService.configure(environment.authentication.authConfig);

    // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/refreshing-a-token-(silent-refresh).html
    this.oAuthService.silentRefreshRedirectUri =
      window.location.origin + '/silent-refresh.html';
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
  }

  public triggerSilentRefresh(): void {
    this.oAuthService
      .silentRefresh()
      // tslint:disable-next-line:no-console
      .then((info) => console.debug('refresh ok', info))
      .catch((err) => console.error('refresh error', err));
  }
}
