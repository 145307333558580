import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { flatMap } from 'rxjs/internal/operators';
import { UserRole } from '../models/user/user-role.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.authentication.active === 'false') {
      return true;
    }

    let loggedIn: Observable<boolean | UrlTree>;

    if (this.authService.discoveryDocument) {
      loggedIn = this.tryLogin(route);
    } else {
      loggedIn = this.authService
        .loadDiscoveryDocument()
        .pipe(mergeMap(() => this.tryLogin(route)));
    }

    return loggedIn.pipe(
      map((isLoggedIn) => {
        return isLoggedIn ? isLoggedIn : this.router.parseUrl('/unauthorized');
      })
    );
  }

  private tryLogin(nextRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.tryLogin().pipe(
      map(() => {
        //If authenticated set up silent refresh, then navigate to route specified by user, return (map to) true
        if (this.authService.hasValidIdToken()) {
          this.authService.setupAutomaticSilentRefresh();
          if (nextRoute && nextRoute.fragment) {
            this.router.navigate([nextRoute.url.join('/')], {
              queryParams: nextRoute.queryParams,
            });
          }

          return true;
        } else {
          this.authService.redirectUri = window.location.origin + '/';
          this.authService.initImplicitFlow();
          return false;
        }
      }),
      //Return / Map to an observable of true booleans
      flatMap((authenticated: boolean) => {
        if (!authenticated) {
          return of(authenticated);
        }

        //Get and Map User to not be null or undefined
        return this.userService.getUser().pipe(
          map((user) => {
            return (
              user !== null &&
              user !== undefined &&
              user.userRoles.includes(UserRole.ADMIN)
            );
          })
        );
      })
    );
  }
}
