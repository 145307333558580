import { Injectable } from '@angular/core';
import { ImageFormatterComponent } from './formatters/image-formatter/image-formatter.component';
import { TextFormatterComponent } from './formatters/text-formatter/text-formatter.component';
import {
  OptionQuantity,
  ViewVariantsFormatterComponent,
} from './formatters/variants-formatters/view-variants-formatter.component';
import { ColDef, RowNode } from 'ag-grid-community';
import { FormatterMethods } from './formatters/FormatterMethods';
import { CategoryFormatterComponent } from './formatters/category-formatter/category-formatter.component';
import { Category } from '../../shared/models/swag/category.model';
import { SwagTableComponent, TableMode } from './swag-table.component';
import { SelectVariantsFormatterComponent } from './formatters/variants-formatters/select-variants-formatter.component';
import { QuantitySelectRendererComponent } from './formatters/quantity-select-renderer.component';
import { Swag } from '../../shared/models/swag/swag.model';
import { SwagService } from '../swag.service';
import {PriceFormatterComponent} from "./formatters/price-formatter/price-formatter.component";

@Injectable({
  providedIn: 'root',
})
export class SwagTableService {
  constructor() {}

  getColumnDefs(tableMode: TableMode, component: SwagTableComponent): ColDef[] {
    let variantsFormatter: any;

    switch (tableMode) {
      case TableMode.VIEW:
        variantsFormatter = ViewVariantsFormatterComponent;
        break;
      case TableMode.PACKAGE_SWAGS:
        variantsFormatter = ViewVariantsFormatterComponent;
        break;
      case TableMode.PACKAGE_VARIANTS:
        variantsFormatter = SelectVariantsFormatterComponent;
        break;
    }

    const colDefs: ColDef[] = [
      { field: 'id', hide: true },
      {
        headerName: 'Bild',
        field: 'image',
        cellRendererFramework: ImageFormatterComponent,
        suppressSizeToFit: true,
        autoHeight: true,
        width: 135,
      },
      {
        headerName: 'Name',
        field: 'title',
        cellRendererFramework: TextFormatterComponent,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: true,
        cellStyle: { lineHeight: '0.95' },
      },
      {
        headerName: 'Varianten',
        field: 'variants',
        cellRendererFramework: variantsFormatter,
        cellRendererParams: {
          notifierCallback: () => component.gridValuesChanged.emit(),
        },
        autoHeight: true,
        sortable: true,
        filter: true,
        filterParams: {
          valueGetter: (rowNode: RowNode) => {
            const variants = rowNode.data.variants;
            const formattedVariants: OptionQuantity[] =
              FormatterMethods.variantsFormatter(variants);
            let renderedString = '';

            formattedVariants.forEach(
              (optionQuantity: OptionQuantity, index: number) => {
                renderedString += `${optionQuantity.title} (${optionQuantity.quantity})`;
                if (index < formattedVariants.length - 1)
                  renderedString += ', ';
              }
            );
            return renderedString;
          },
        },
        resizable: true,
      },
      {
        headerName: 'Preis',
        field: 'price',
        cellRendererFramework: PriceFormatterComponent,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: true,
        cellStyle: { lineHeight: '0.95' },
      },
    ];
    if (tableMode === TableMode.VIEW || tableMode === TableMode.PACKAGE_SWAGS) {
      colDefs.push({
        headerName: 'Kategorien',
        field: 'categories',
        cellRendererFramework: CategoryFormatterComponent,
        autoHeight: true,
        sortable: true,
        comparator: this._categoryComparator,
        resizable: true,
        filter: true,
        filterParams: {
          valueGetter: (rowNode: RowNode) => {
            const categories = rowNode.data.categories;
            let categoriesString = '';

            categories.forEach((category: Category, index: number) => {
              categoriesString += category.name;
              if (index != categories.length) {
                categoriesString += ', ';
              }
            });

            return categoriesString;
          },
        },
      });
    }

    if (tableMode === TableMode.PACKAGE_VARIANTS) {
      colDefs.push({
        headerName: 'Menge',
        field: 'quantity',
        autoHeight: true,
        resizable: true,
        cellRendererFramework: QuantitySelectRendererComponent,
        cellRendererParams: {
          notifierCallback: () => component.gridValuesChanged.emit(),
        },
      });
    }

    return colDefs;
  }

  protected _categoryComparator(valA: Category[], valB: Category[]): number {
    if (valB.length === 0) return valA.length === 0 ? 0 : 1;
    if (valA.length === 0) return -1;

    let i = 0;
    for (i; i < valA.length; i++) {
      if (i === valB.length) return 1;

      const a = valA[i].name;
      const b = valB[i].name;

      if (a === b) continue;
      return a > b ? 1 : -1;
    }
    return valB.length === valA.length ? 0 : -1;
  }

  //Return list of all swags given in loadedSwagsCopy
  static generateViewSwags(
    loadedSwagsCopy: { [p: string]: Swag } | null
  ): { [p: string]: Swag } | null {
    return loadedSwagsCopy;
  }

  //Return list of all swags given in loadedSwagsCopy whose id matches an id in chosenSwagIds
  static generatePackageVariants(
    loadedSwagsCopy: { [p: string]: Swag } | null,
    chosenSwagIds: string[] | undefined
  ): { [p: string]: Swag } | null {
    let swagList: { [p: string]: Swag } | null = null;

    if (chosenSwagIds) {
      swagList = {};
      chosenSwagIds.forEach(
        (swagId) => (swagList![swagId] = loadedSwagsCopy![swagId])
      );
    }
    return swagList;
  }

  //Return a list of all swags given in loadedSwagsCopy minus packages and swags whose id matches ids in chosenSwagIds
  static generatePackageSwags(
    loadedSwagsCopy: { [p: string]: Swag },
    chosenSwagIds: string[]
  ): { [p: string]: Swag } | null {
    //Remove packages from loadedSwagsCopy
    Object.keys(loadedSwagsCopy).forEach((swagId) => {
      if (SwagService.isPackage(loadedSwagsCopy[swagId])) {
        delete loadedSwagsCopy[swagId];
      }
    });
    //Remove swags already in package from loadedSwagsCopy
    if (chosenSwagIds) {
      chosenSwagIds.forEach(
        (chosenSwagId) => delete loadedSwagsCopy[chosenSwagId]
      );
    }
    return loadedSwagsCopy;
  }
}
