<div class="header-outer-box">
  <div class="header-inner-box">
    <div class="left-side">
      <img
        [src]="codecentricLogo.path"
        [alt]="codecentricLogo.alt"
        class="logo"
        routerLink="/dashboard"
        (mouseover)="onLogoEnter()"
        (mouseout)="onLogoLeave()"
      />
      <div class="vl">
        <p>Swagshop Adminpanel</p>
      </div>
    </div>

  </div>
</div>
<app-navbar></app-navbar>
