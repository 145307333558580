import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  codecentricLogo = {
    path: 'assets/images/cc-big-sign-black-2.png',
    alt: 'codecentric logo',
  };
  constructor(private oAuthService: AuthenticationService) {}

  ngOnInit(): void {}

  onLogoEnter() {
    this.codecentricLogo.path = 'assets/images/cc-big-sign-2.png';
  }

  onLogoLeave() {
    this.codecentricLogo.path = 'assets/images/cc-big-sign-black-2.png';
  }
}
