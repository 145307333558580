import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swags',
  templateUrl: './swags.component.html',
  styleUrls: ['./swags.component.scss'],
})
export class SwagsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
