import { Component, OnDestroy } from '@angular/core';
import { Variant } from '../../../../shared/models/swag/variant.model';
import { ICellRendererParams } from 'ag-grid-community';
import { SwagEditService } from '../../../swag-edit/swag-edit.service';
import { FormGroup } from '@angular/forms';
import { EditSwagValidatorsDirective } from '../../../swag-edit/validators/edit-swag-validators.directive';
import { Subscription } from 'rxjs';

interface CustomParams extends ICellRendererParams {
  notifierCallback: () => void;
}

@Component({
  selector: 'app-select-variants-formatter',
  template: ` <div *ngIf="form" [formGroup]="form!">
    <select (change)="updateVariant($event.target)" [formControlName]="key!">
      <option *ngIf="!selectedVariantId" [value]="null">Bitte auswählen</option>
      <option *ngFor="let variant of variants" [value]="variant.id">
        {{ variant.label ? variant.label : variant.title }}
        ({{ variant.quantity }})
      </option>
    </select>
  </div>`,
  styles: [
    `
      option,
      select {
        font-size: 13px;
        width: 115px;
      }
      select.ng-invalid {
        border: 2px solid red;
      }
    `,
  ],
})
export class SelectVariantsFormatterComponent implements OnDestroy {
  variantSelected = false;
  variants?: Variant[];
  chosenVariant: Variant | null = null;
  selectedVariantId: string | null = null;
  form?: FormGroup;
  key?: string;
  notifierCallback?: () => void;
  selectedVariantSub?: Subscription;

  constructor(private swagEditService: SwagEditService) {}

  agInit(params: CustomParams) {
    this.form = params.context.formGroup;
    this.key = params.context.createKey(params.node, params.column);
    this.notifierCallback = params.notifierCallback;

    this.form!.get(this.key!)!.addValidators(
      new EditSwagValidatorsDirective().variantSelectionValidator()
    );
    this.variants = <Variant[]>params.data.variants;

    this.selectedVariantSub = this.swagEditService.selectedVariant.subscribe(
      (variant) => {
        if (variant) {
          this.chosenVariant = variant;
          this.selectedVariantId = null;
          this.variantSelected = false;
          if (!this.variants!.find((variant) => this.isSelected(variant)))
            this.form!.get(this.key!)!.patchValue(null);
          this.notifierCallback!();
        }
      }
    );
  }

  updateVariant(target: any) {
    const newSelectedVariantId = target.value;

    //If a variant was already selected, replace its id
    if (this.selectedVariantId) {
      const selectedVariantIndex: number =
        this.chosenVariant?.packagedVariantIds?.indexOf(
          this.selectedVariantId
        )!;

      this.chosenVariant!.packagedVariantIds![selectedVariantIndex] =
        newSelectedVariantId;
      //Otherwise, add new variant to selection
    } else {
      this.chosenVariant!.packagedVariantIds!.push(newSelectedVariantId);
      this.variantSelected = true;
    }

    this.selectedVariantId = newSelectedVariantId;
    this.notifierCallback!();
  }

  isSelected(variant: Variant): boolean {
    //TODO: Avoid constant updating
    if (
      this.chosenVariant &&
      this.chosenVariant.packagedVariantIds!.includes(variant.id!)
    ) {
      this.selectedVariantId = variant.id!;
      this.variantSelected = true;
      this.form!.get(this.key!)!.patchValue(this.selectedVariantId);
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.selectedVariantSub?.unsubscribe();
  }
}
