<div class="table-container">
  <ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="COLUMN_DEFS"
    (firstDataRendered)="$event.api.sizeColumnsToFit()"
    [rowData]="rowData"
    (gridReady)="onGridReady()"
    (gridSizeChanged)="$event.api.sizeColumnsToFit()"
  ></ag-grid-angular>
</div>
