import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { SwagsComponent } from './swags/swags.component';
import { SwagCardComponent } from './swags/card/swag-card.component';
import { SwagTableComponent } from './swags/swag-table/swag-table.component';
import { SwagEditComponent } from './swags/swag-edit/swag-edit.component';
import { CanActivateEditGuardService } from './swags/swag-edit/can-activate-edit-guard.service';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { CanActivateCardGuard } from './swags/card/can-activate-card.guard';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { UsersManagementComponent } from './users/users-management.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      { path: 'orders', component: OrdersComponent },
      { path: 'users', component: UsersManagementComponent },
      {
        path: 'swags',
        component: SwagsComponent,
        children: [
          {
            path: 'package/edit',
            component: SwagEditComponent,
            canActivate: [CanActivateEditGuardService],
          },
          {
            path: 'edit',
            component: SwagEditComponent,
            canActivate: [CanActivateEditGuardService],
          },
          {
            path: '',
            component: SwagTableComponent,
          },
          {
            path: ':id',
            component: SwagCardComponent,
            outlet: 'card',
            canActivate: [CanActivateCardGuard],
          },

          {
            path: '',
            component: SwagCardComponent,
            outlet: 'card',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
