import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Modal } from '../../models/modal';

@Component({
  selector: 'app-modal[modal]',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('externallyFilledModal') externallyFilledPart?: ElementRef;

  @Input() modal!: Modal;
  inputRequired: boolean = false;
  viewLoaded: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.inputRequired = this.modal.input !== undefined;
  }

  ngAfterViewInit(): void {
    this.viewLoaded = true;
    this.fillInHtmlCode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillInHtmlCode();
  }

  private fillInHtmlCode() {
    if (!this.viewLoaded) return;
    if (this.modal.htmlCode) {
      this.externallyFilledPart!.nativeElement.innerHTML = this.modal.htmlCode;
    } else {
      this.externallyFilledPart!.nativeElement.innerHTML = '';
    }
  }

  onCancel() {
    this.modal.cancel!();
  }

  onApply() {
    this.modal.apply!();
  }
}
