import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SwagService } from '../swag.service';
import { PackagedSwag, Swag } from '../../shared/models/swag/swag.model';
import { Variant } from '../../shared/models/swag/variant.model';
import { Category } from '../../shared/models/swag/category.model';
import { CategoryService } from '../../shared/services/category.service';
import { SwagEditService } from './swag-edit.service';
import { skip, take } from 'rxjs/operators';
import { Modal } from '../../shared/models/modal';
import { environment } from '../../../environments/environment';
import { EditSwagValidatorsDirective } from './validators/edit-swag-validators.directive';
import { TableMode } from '../swag-table/swag-table.component';

@Component({
  selector: 'app-swag-edit',
  templateUrl: './swag-edit.component.html',
  styleUrls: ['./swag-edit.component.scss'],
})
export class SwagEditComponent implements OnInit, OnDestroy {
  validators = new EditSwagValidatorsDirective();
  addSwagDialogueOpen = false;
  packagedSwagIds?: string[];
  editForm: FormGroup = new FormGroup({
    title: new FormControl(undefined, Validators.required),
    category: new FormControl(),
    price: new FormControl(undefined, this.validators.priceValidator()),
    variantTitle: new FormControl(undefined, Validators.required),
    variantOptions: new FormControl(
      undefined,
      this.validators.variantLabelValidator()
    ),
    variantQuantity: new FormControl(undefined, [
      Validators.required,
      Validators.min(0),
      this.validators.variantQuantityValidator(),
    ]),
    gridGroup: new FormGroup({}),
  });
  gridGroup: FormGroup = <FormGroup>this.editForm.get('gridGroup')!;
  loadedSwag: Swag | null = null;
  categoryCreation = environment.categoryCreation;
  uneditedLoadedSwag?: Swag;
  chosenVariant?: Variant;
  variantAddable: boolean = true;
  categories?: Category[];
  addNewCategoryText: string = 'Neue Kategorie hinzufügen';
  noCategorySelectedText: string = 'Keine';
  selectedCategory?: Category;
  modal?: Modal;
  skipNextVariantUpdate = false;
  imageValid: boolean = true;
  variantTableMode = TableMode.PACKAGE_VARIANTS;
  swagTableMode = TableMode.PACKAGE_SWAGS;
  swagIdSub$?: Subscription;
  chosenVariantSub$?: Subscription;
  categorySub$?: Subscription;
  saveSwagSub$: Subscription = this.swagEditService.saveSwag.subscribe(() =>
    this.onSave()
  );
  isPackage = false;
  closing = false;

  changeSubs$: { [field: string]: Subscription } = {};
  loadedSwagSub$?: Subscription;
  proposeVariantSub$?: Subscription;

  cancelEditing = () => {
    this.swagEditService.setSelectedVariant(null);
    this.closing = true;
    if (this.loadedSwag) {
      if (this.swagEditService.addingSwag) {
        this.swagsService.deleteSwag(this.loadedSwag?.id!);
      } else {
        this.loadedSwag.title = this.uneditedLoadedSwag!.title;
        this.loadedSwag.price = this.uneditedLoadedSwag!.price;
        this.loadedSwag.variants = this.uneditedLoadedSwag!.variants;
        this.loadedSwag.categories = this.uneditedLoadedSwag!.categories;
        this.loadedSwag.image = this.uneditedLoadedSwag!.image;
        this.loadedSwag.packagedSwags = this.uneditedLoadedSwag?.packagedSwags;
        this.swagEditService.emitVariantUpdated();
      }
    }
  };

  cancelEditingSub$ = this.swagEditService.cancelEditing.subscribe(() => {
    this.cancelEditing();
  });

  constructor(
    private route: ActivatedRoute,
    private swagsService: SwagService,
    private categoryService: CategoryService,
    protected swagEditService: SwagEditService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let cardRoute = this.route.parent?.children.filter((child) => {
      return child.outlet === 'card';
    });

    this.isPackage = window.location.pathname.includes('package');
    this.swagEditService.setEditMode(true);

    this.swagIdSub$ = cardRoute![0].paramMap.subscribe((params) => {
      this._onUpdateSwagId(params.get('id')!);
    });

    this.loadedSwagSub$ = this.swagsService.loadedSwags
      .pipe(skip(1))
      .subscribe(() => {
        cardRoute![0].paramMap.pipe(take(1)).subscribe((params) => {
          const routeId = params.get('id')!;
          this._onUpdateSwagId(routeId);
        });
      });

    this.chosenVariantSub$ = this.swagEditService.selectedVariant.subscribe(
      (variant) => {
        this._onUpdateChosenVariant(variant);
      }
    );

    this.proposeVariantSub$ =
      this.swagEditService.proposeSelectedVariant.subscribe(
        (variant: Variant | null) => {
          this._onProposeUpdateChosenVariant(variant);
        }
      );

    this._imageValid();
  }

  protected setPackagedSwagIds() {
    this.packagedSwagIds = this.loadedSwag?.packagedSwags?.map(
      (packagedSwag) => packagedSwag.swagId
    );
    const newIds = this.packagedSwagIds ? [...this.packagedSwagIds] : [];
    if (this.loadedSwag!.id) newIds.push(this.loadedSwag!.id);
  }

  //Subscribe to input events
  protected _subscribeToChanges() {
    if (this.loadedSwag) {
      this.changeSubs$['title'] = this.editForm
        .get('title')!
        .valueChanges.subscribe((title) => {
          this.loadedSwag!.title = title;
          this.formValid();
        });
      this.changeSubs$['price'] = this.editForm
        .get('price')!
        .valueChanges.subscribe((price) => {
          this.loadedSwag!.price = price;
          this.formValid();
        });
      this.changeSubs$['category'] = this.editForm
        .get('category')!
        .valueChanges.subscribe((categoryName) => {
          const category = this.matchCategoryName(categoryName);

          if (categoryName === this.addNewCategoryText) return;

          this.loadedSwag!.categories = [];

          if (category) this.loadedSwag?.categories.push(category);

          this.selectedCategory = category;
          this.formValid();
        });
    }
  }

  matchCategoryName(categoryName: string): Category | undefined {
    if (this.addNewCategoryText === categoryName && this.categoryCreation) {
      this.modal = {
        questionText: 'Bitte gib der neuen Kategorie einen Namen',
        applyButtonText: 'Kategorie erstellen',
        cancelButtonText: 'Abbrechen',
        input: '',
        apply: () => this.onAddNewCategory(),
        cancel: () => this.hideCategoryModal(),
      };
      return undefined;
    }

    let matchedCategory: Category | undefined;

    this.categories?.forEach((loadedCategory) => {
      if (loadedCategory.name === categoryName)
        matchedCategory = loadedCategory;
    });

    return matchedCategory;
  }

  protected _unsubscribeFromVariantChanges() {
    const keys = Object.keys(this.changeSubs$);

    keys.forEach((key) => {
      if (key !== 'title' && key !== 'category' && key !== 'price') {
        this.changeSubs$[key].unsubscribe();
      }
    });
  }

  //Subscribe to input events concerning variants
  protected _subscribeToVariantChanges() {
    //Unsubscribe first
    this._unsubscribeFromVariantChanges();

    //Resubscribe for new Variant
    if (this.chosenVariant) {
      this.changeSubs$['variantTitle'] = this.editForm
        .get('variantTitle')!
        .valueChanges.subscribe((variantTitle) => {
          this.chosenVariant!.title = variantTitle;
          this.formValid();
        });

      this.changeSubs$['variantOptions'] = this.editForm
        .get('variantOptions')!
        .valueChanges.subscribe((variantOptions) => {
          if (!variantOptions || variantOptions === '') {
            this.chosenVariant!.label = undefined;
          } else {
            this.chosenVariant!.label = variantOptions;
          }
          this.swagEditService.emitVariantUpdated();
          this.formValid();
        });

      this.changeSubs$['variantQuantity'] = this.editForm
        .get('variantQuantity')!
        .valueChanges.subscribe((variantQuantity) => {
          this.chosenVariant!.quantity = variantQuantity;
          this.formValid();
        });
    }
  }

  //Assign new loadedSwag and clone it for cancelling
  protected _onUpdateSwagId(swagId: string) {
    if (this.closing) return;
    this.swagsService
      .getSwagById(swagId)
      .pipe(take(1))
      .subscribe((swag) => {
        if (swag) {
          this.loadedSwag = swag;
          this.setPackagedSwagIds();
          this.validators.loadedSwag = this.loadedSwag;

          const gridFormGroup = this.editForm.get('gridGroup');

          if (
            this.isPackage &&
            gridFormGroup &&
            !gridFormGroup.hasValidator(
              this.validators.packagedSwagsGroupValidator()
            )
          ) {
            gridFormGroup.setValidators(
              this.validators.packagedSwagsGroupValidator()
            );
          }
          this.uneditedLoadedSwag = JSON.parse(JSON.stringify(this.loadedSwag));

          this.editForm.get('title')?.setValue(this.loadedSwag?.title);
          this.editForm.get('price')?.setValue(this.loadedSwag?.price);

          //If user didn't select a variant when picking the card, choose the first one
          if (this.loadedSwag.variants.length >= 1) {
            const selectedVariant =
              this.swagEditService.selectedVariant.getValue();
            //If a variant is loaded that belongs to the loadedSwag, but is not in the object of the loadedSwag, replace it with the variant in the object of the loadedSwag
            if (
              selectedVariant &&
              selectedVariant !==
                this.loadedSwag.variants.find(
                  (variant) => variant.id === selectedVariant.id
                )
            ) {
              this.swagEditService.setSelectedVariant(
                this.loadedSwag.variants.find(
                  (variant) => variant.id === selectedVariant.id
                )!
              );
            }
          }
        } else {
          this.cancelEditing();
        }
        this._subscribeToChanges();
        this._subscribeToCategories();
      });
  }

  protected _onProposeUpdateChosenVariant(variant: Variant | null) {
    if (!variant && !this.chosenVariant) {
      this.swagEditService.setSelectedVariant(this.loadedSwag!.variants[0]);
    } else if (
      (this.chosenVariant && this._validVariant()) ||
      !this.chosenVariant
    ) {
      this.swagEditService.setSelectedVariant(variant);
    }
  }

  protected _onUpdateChosenVariant(variant: Variant | null) {
    if (this.skipNextVariantUpdate) {
      this.skipNextVariantUpdate = false;
      return;
    }
    let newTitle = '';
    let newOption: undefined | string = '';
    let newQuantity: string | number = '';
    let newPackagedVariantIds: string[] = [];

    if (variant) {
      this.chosenVariant = variant;

      newTitle = variant.title;
      newOption = variant.label;
      newQuantity = variant.quantity;
      if (!variant.packagedVariantIds) {
        variant.packagedVariantIds = newPackagedVariantIds;
      }

      this._subscribeToVariantChanges();
    } else {
      this._unsubscribeFromVariantChanges();
      this.chosenVariant = undefined;
    }

    this.editForm.get('variantTitle')?.setValue(newTitle);

    this.editForm.get('variantOptions')?.setValue(newOption);

    this.editForm.get('variantQuantity')?.setValue(newQuantity);
  }

  //Delete Variant from variant array and remove subscriptions / text from input sources
  onDeleteVariant() {
    if (!this.loadedSwag) return;
    if (this.loadedSwag?.variants.length <= 1) {
      this.modal = {
        questionText: 'Die letzte Variante kann nicht gelöscht werden',
        applyButtonText: 'Bestätigen',
        apply: () => (this.modal = undefined),
      };

      return;
    }

    let newlyChosenVariantIndex: number | null = null;

    this.loadedSwag?.variants.forEach((variant, index) => {
      if (variant === this.chosenVariant) {
        this.loadedSwag?.variants.splice(index, 1);

        if (index === this.loadedSwag?.variants.length)
          newlyChosenVariantIndex = 0;
        else newlyChosenVariantIndex = index;
      }
    });
    const newVariantChosen =
      newlyChosenVariantIndex !== null && !isNaN(newlyChosenVariantIndex);

    this.swagEditService.setSelectedVariant(
      newVariantChosen
        ? this.loadedSwag.variants[newlyChosenVariantIndex!]
        : null
    );
    this.swagEditService.emitVariantUpdated();
  }

  //Add a new standard variant to swag and fill it into input fields. Subscribe variant to input fields
  onAddVariant() {
    if (!this._validVariant()) return;

    let newVariant: Variant = {
      title: `${this.loadedSwag?.title} in`,
      label: undefined,
      quantity: 0,
    };
    if (SwagService.isPackage(this.loadedSwag!))
      newVariant.packagedVariantIds = [];
    this.loadedSwag?.variants.push(newVariant);

    this.swagEditService.setSelectedVariant(newVariant);
    this._validVariant();
  }

  protected _validVariant(): boolean {
    const titleValid = this.editForm.get('variantTitle')!.valid;
    const inventoryQuantityValid = this.editForm.get('variantQuantity')!.valid;
    const optionValuesValid = this.editForm.get('variantOptions')!.valid;
    let gridValid = this.isPackage
      ? this.editForm.get('gridGroup')!.valid
      : true;

    const isValid =
      titleValid && inventoryQuantityValid && optionValuesValid && gridValid;
    this.variantAddable = isValid;
    return isValid;
  }

  //Destroy subscriptions and remove edit selections
  ngOnDestroy() {
    if (this.swagIdSub$) this.swagIdSub$!.unsubscribe();
    this.chosenVariantSub$!.unsubscribe();
    this.proposeVariantSub$?.unsubscribe();

    if (this.changeSubs$) {
      Object.values(this.changeSubs$).forEach((subscription) =>
        subscription.unsubscribe()
      );
    }
    this.cancelEditingSub$.unsubscribe();
    this.loadedSwagSub$?.unsubscribe();
    this.swagEditService.setEditMode(false);
    this.categorySub$!.unsubscribe();
    this.swagEditService.imageEdited = false;
    this.saveSwagSub$.unsubscribe();
    this.swagEditService.setSelectedVariant(null);
    this.swagEditService.setProposeSelectedVariant(null);
    this.swagEditService.newImageName = undefined;
  }

  onChangeImage($event: File) {
    const reader = new FileReader();
    reader.readAsDataURL($event);

    const image = new Image();

    image.onload = () => {
      if (image.width > 520) {
        this.modal = {
          questionText: 'Das Bild darf nicht breiter als 520 pixel sein',
          applyButtonText: 'Bestätigen',
          apply: () => (this.modal = undefined),
        };
      } else {
        this.loadedSwag!.image = image.src;
        this.swagEditService.newImageName = $event.name;
        this.swagEditService.imageEdited = true;
        this.formValid();
      }
    };

    reader.onload = () => {
      image.src = <string>reader.result;
    };
  }

  protected _subscribeToCategories() {
    this.categorySub$ = this.categoryService.cachedCategories.subscribe(
      (categories) => {
        this.categories = categories;

        if (
          this.loadedSwag?.categories &&
          this.loadedSwag.categories.length > 0
        )
          this.selectedCategory = this.loadedSwag.categories[0];
      }
    );
  }

  onAddNewCategory() {
    if (!environment.categoryCreation) return;
    if (!this.modal || !this.modal.input) {
      this.hideCategoryModal();
      return;
    }

    const categoryName: string = this.modal!.input!;

    this.categoryService
      .createCategory({ name: categoryName })
      .subscribe((newCategory) => {
        this.loadedSwag!.categories = [];
        this.loadedSwag?.categories.push(newCategory);

        let cachedCategoriesValue =
          this.categoryService.cachedCategories.getValue();

        if (!cachedCategoriesValue) cachedCategoriesValue = [];
        cachedCategoriesValue?.push(newCategory);

        this.categoryService.setCachedCategories(cachedCategoriesValue);
        this.hideCategoryModal();
      });
  }

  hideCategoryModal() {
    this.modal = undefined;

    const categorySelector = this.editForm.get('category')!;

    if (!this.selectedCategory)
      categorySelector.setValue(this.noCategorySelectedText);
    else categorySelector.setValue(this.selectedCategory?.name);
  }

  onSave() {
    if (!this.formValid()) return;

    this.swagsService
      .updateAndReloadSwag(this.loadedSwag?.id!)
      .pipe(take(1))
      .subscribe(
        () => this.router.navigate(['swags']),
        (error) => {
          this.modal = {
            questionText: JSON.stringify(error.error.error),
            apply: () => {
              this.swagEditService.emitCancelEditing();
              this.modal = undefined;
            },
            applyButtonText: 'Okay',
          };
        }
      );
  }

  onChangeImageFromSelection($event: Event) {
    this.onChangeImage((<HTMLInputElement>$event.target).files![0]);
  }

  formValid(): boolean {
    const validPackage = (): boolean => {
      let allValuesValid = true;
      this.loadedSwag?.variants.forEach((variant) => {
        allValuesValid =
          variant.packagedVariantIds?.length ===
          this.loadedSwag?.packagedSwags?.length;
      });
      return allValuesValid && SwagService.isPackage(this.loadedSwag!);
    };

    const titleValid = this.editForm.get('title')!.valid;
    const priceValid = this.editForm.get('price')!.valid;
    let baseVariantValid = this._validVariant();
    let packageValid = true;
    if (this.isPackage) packageValid = validPackage();

    const imageValid = this._imageValid();
    const validForm =
      titleValid &&
      baseVariantValid &&
      packageValid &&
      imageValid &&
      priceValid;

    if (validForm !== this.swagEditService.validForm.getValue()) {
      this.swagEditService.setValidForm(validForm);
    }

    return validForm;
  }

  protected _imageValid(): boolean {
    const valid = !(
      this.swagEditService.addingSwag &&
      this.swagEditService.newImageName === undefined
    );
    this.imageValid = valid;
    return valid;
  }

  onRequestAddSwag() {
    this.addSwagDialogueOpen = true;
  }

  onAddSwagsToPackage(swagIds: string[]) {
    this.addSwagDialogueOpen = false;
    if (!this.packagedSwagIds) this.packagedSwagIds = [];
    this.packagedSwagIds?.push(...swagIds);
    //Assign new array to trigger change detection
    this.packagedSwagIds = [...this.packagedSwagIds];

    swagIds.forEach((swagId) => {
      const packagedSwag: PackagedSwag = {
        swagId: swagId,
        quantity: 1,
      };
      this.loadedSwag?.packagedSwags?.push(packagedSwag);
    });
  }

  onRemoveSwagsFromPackage(swagIds: string[]) {
    const removedSwagIds: string[] = [];

    swagIds.forEach((swagId) => {
      const packagedSwag = this.loadedSwag?.packagedSwags?.find(
        (packagedSwag) => packagedSwag.swagId === swagId
      )!;

      const index = this.loadedSwag!.packagedSwags!.indexOf(packagedSwag);
      if (index !== -1) {
        this.loadedSwag?.packagedSwags?.splice(index, 1);
        removedSwagIds.push(swagId);
      }
    });
    this.removeSwagVariantsFromPackage(removedSwagIds);
    this.setPackagedSwagIds();
  }

  protected removeSwagVariantsFromPackage(swagIds: string[]) {
    //Get all swags belonging to the swagIds
    this.swagsService.getSwagsByIds(swagIds).subscribe((swags) => {
      const packagedVariantIdsToRemove: string[] = [];
      //Get all variantIds contained in the swags
      swags.forEach((swag) => {
        swag.variants.forEach((packagedVariantIdToRemove) => {
          packagedVariantIdsToRemove.push(packagedVariantIdToRemove.id!);
        });
      });

      //Get all variants of the package and loop
      this.loadedSwag?.variants.forEach((packageVariant) => {
        //Get all variants contained in the variants of the package
        const containedVariantIds = packageVariant.packagedVariantIds?.filter(
          (packagedVariantId) =>
            packagedVariantIdsToRemove.includes(packagedVariantId)
        );
        //if containedVariantIds were found, remove them from the object.
        if (containedVariantIds) {
          containedVariantIds.forEach((containedVariantId) => {
            const index =
              packageVariant.packagedVariantIds?.indexOf(containedVariantId)!;
            if (index !== -1)
              packageVariant.packagedVariantIds?.splice(index, 1);
          });
        }
      });
    });
  }

  protected getVariantSwagLookupTable(): { [variantId: string]: string } {
    const lookupTable: { [variantId: string]: string } = {};
    const swagIds = this.loadedSwag?.packagedSwags?.map(
      (packagedSwag) => packagedSwag.swagId
    )!;

    swagIds.forEach((swagId) => {
      const swag = this.swagsService.loadedSwags.getValue()![swagId];
      swag.variants.forEach(
        (variant) => (lookupTable[variant!.id!] = swag?.id!)
      );
    });
    return lookupTable;
  }
}
