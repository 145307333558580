<div class="modal">
  <div class="modal-content">
    <div class="header-container">
      <p style="text-align: center">
        {{ modal.questionText }}
      </p>
    </div>
    <div *ngIf="inputRequired" class="input-container">
      <input id="input-element" [(ngModel)]="modal.input"/>
    </div>
    <div #externallyFilledModal></div>

    <div class="modal-button-container">
      <button
        *ngIf="modal.applyButtonText !== ''"
        id="applyButton"
        class="primary-button"
        (click)="onApply()"
        [disabled]="inputRequired ? modal.input!.length === 0 : false"
      >
        {{ modal.applyButtonText }}
      </button>
      <button
        *ngIf="modal.cancelButtonText"
        id="cancelButton"
        class="secondary-button"
        (click)="onCancel()"
      >
        {{ modal.cancelButtonText }}
      </button>
    </div>
  </div>
</div>

