import { Component } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { UserRole } from './shared/models/user/user-role.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SwagShopAdminPanel';
  allowedUserRole = UserRole.ADMIN;
  userAllowedToEnter = () =>
    this.userService.cachedUser &&
    this.userService.cachedUser?.userRoles.includes(this.allowedUserRole);

  constructor(public userService: UserService) {}
}
