<div class="container">
  <div class="table-container">
    <ag-grid-angular
      *ngIf="!gridGroup"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="tableMode === 0 ? 'single' : 'multiple'"
      (rowSelected)="onSelectRow($event)"
      (gridSizeChanged)="onGridSizeChanged()"
      (gridReady)="onGridReady()"
      (firstDataRendered)="onFirstDataRendered()"
      [domLayout]="tableMode === 0 || tableMode === 2 ? 'normal' : 'autoHeight'"
      [ngClass]="getGridClasses()"
      (selectionChanged)="onRowSelection()"
      [context]="getContext()"
      #tableGrid
    >
    </ag-grid-angular>

      <ag-grid-angular
        *ngIf="gridGroup"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [rowData]="rowData"
        [rowSelection]="tableMode === 0 ? 'single' : 'multiple'"
        (rowSelected)="onSelectRow($event)"
        (gridSizeChanged)="onGridSizeChanged()"
        (gridReady)="onGridReady()"
        (firstDataRendered)="onFirstDataRendered()"
        [domLayout]="tableMode === 0 || tableMode === 2 ? 'normal' : 'autoHeight'"
        [ngClass]="getGridClasses()"
        (selectionChanged)="onRowSelection()"
        [context]="getContext()"
        [formGroup]="gridGroup"
        #tableGrid
      >
      </ag-grid-angular>
    <div class="grid-button-container">
      <button *ngIf="allowExport" class="primary-button" (click)="exportAsCsv()">Als CSV exportieren</button>
    </div>
    <div *ngIf="tableMode === 1" class="grid-button-container">
      <button class="primary-button" (click)="addSwagRequestEvent.emit()">Swag hinzufügen</button>
      <button class="secondary-button" (click)="onRemovePackageSwags()" [disabled]="!rowSelected">Swags entfernen</button>
    </div>
    <div *ngIf="tableMode === 2" class="grid-button-container">
      <button class="primary-button" [disabled]="!rowSelected" (click)="fireAddSwagToPackageEvent()">Swag hinzufügen</button>
      <button class="secondary-button" (click)="cancelSwagRequestEvent.emit()">Abbrechen</button>
    </div>
  </div>
</div>
