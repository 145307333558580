export const environment = {
  production: true,
  deleteOrder: true,
  categoryCreation: false,
  isProd: false,
  authentication: {
    active: 'true',
    openIdConfigUrl:
      'https://login.codecentric.de/auth/realms/apps-cc/.well-known/openid-configuration',
    authConfig: {
      clientId: 'swag-shop',
      issuer: 'https://login.codecentric.de/auth/realms/apps-cc',
      strictDiscoveryDocumentValidation: false,
    },
  },
  apiConfig: {
    apiURL: 'https://api.dev.swagshop.codecentric.de',
    apiGatewayUrl:
      'https://k7a8tr2753.execute-api.eu-central-1.amazonaws.com/default',
  },
};
