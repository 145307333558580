<div class="container">
<ag-grid-angular
  class="ag-theme-alpine"
  rowSelection="multiple"
  [columnDefs]="COLUMN_DEFS"
  (firstDataRendered)="$event.api.sizeColumnsToFit()"
  [frameworkComponents]="frameworkComponents"
  [rowData]="rowData"
  (gridReady)="onGridReady()"
  (gridSizeChanged)="onGridSizeChanged()"
  (selectionChanged)="onRowSelection()"
  #tableGrid
></ag-grid-angular>

  <div class="button-container">
  <button class="primary-button grid-button-container" (click)="onExportAsCsv()">Als CSV exportieren</button>
    <button *ngIf="deleteOrder"  class="secondary-button delete-button" (click)="onDelete()" [disabled]="!rowSelected">Löschen</button>
  </div>
</div>

<app-modal id="app-modal" *ngIf="modal" [modal]="modal"></app-modal>
