import { Component } from '@angular/core';
import { Category } from '../../../../shared/models/swag/category.model';

@Component({
  selector: 'app-category-formatter',
  template:
    '<span *ngFor="let category of categories!, last as isLast">{{ category.name }}{{isLast ? "" : ", "}}</span>',
  styles: ['span {font-size: 13px; font-family: panton-bold, sans-serif;}'],
})
export class CategoryFormatterComponent {
  categories: Category[] = [];

  agInit(params: any) {
    this.categories = <Category[]>params.getValue();
  }
}
