import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cc-items-cell-renderer',
  templateUrl: './items-cell-renderer.component.html',
  styleUrls: ['./items-cell-renderer.component.scss'],
})
export class ItemsCellRendererComponent {
  public items?: string[];

  agInit(params: ICellRendererParams): void {
    this.items = params.value.split(',');
  }

  refresh(params: ICellRendererParams) {
    this.items = params.value.split(',');
  }
}
