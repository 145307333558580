import { Component } from '@angular/core';
import { UserRole } from '../shared/models/user/user-role.model';
import { ICellRendererParams } from 'ag-grid-community';
import { User } from '../shared/models/user/user.model';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-user-role-renderer',
  template: `
    <div
      *ngFor="let userRole of assignableUserRoles; let i = index"
      [class.not-first]="i > 0"
    >
      <input
        type="checkbox"
        id="{{ userRole + user!.id! }}"
        [checked]="isAssigned(userRole)"
        (change)="onChange($event.target!, userRole)"
      />
      <label for="{{ userRole + user!.id! }}">{{ userRole }}</label>
    </div>
  `,
  styles: [
    `
      div {
        display: inline-block;
      }
      .not-first {
        margin-left: 15px;
      }
    `,
  ],
})
export class UserRoleRendererComponent {
  assignableUserRoles = [UserRole.ORDERER, UserRole.ADMIN];
  assignedUserRoles: UserRole[] = [];
  user?: User;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  agInit(params: ICellRendererParams) {
    this.user = params.data.user;
    this.assignedUserRoles = params.data.userRoles;
  }

  isAssigned(userRole: UserRole) {
    return this.assignedUserRoles.includes(userRole);
  }

  onChange(target: EventTarget, userRole: UserRole) {
    const userRoleIndex = this.assignedUserRoles.indexOf(userRole);
    let userRolesUpdated = false;
    // @ts-ignore
    if (target.checked) {
      if (userRoleIndex === -1) {
        this.assignedUserRoles.push(userRole);
        userRolesUpdated = true;
      }
    } else if (userRoleIndex !== -1) {
      this.assignedUserRoles.splice(userRoleIndex, 1);
      userRolesUpdated = true;
    }

    if (userRolesUpdated) {
      this.userService.updateUser(this.user!).subscribe();
    }
  }
}
