<div class="container">
  <div class="left-container">
    <router-outlet></router-outlet>
    <!--  <app-swag-table></app-swag-table>-->
  </div>
  <div class="right-container">
    <router-outlet name="card"></router-outlet>
    <!--  <router-outlet></router-outlet>-->
  </div>
</div>
