import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Order, OrderStatus} from '../shared/models/order/order.model';
import {HttpClient} from '@angular/common/http';
import {ApiConfig} from '../shared/models/api-config.model';
import {map, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  loadedOrders = new BehaviorSubject<Order[] | null>(null);

  constructor(private httpClient: HttpClient, private apiConfig: ApiConfig) {
    this.getOrders().pipe(take(1)).subscribe();
  }

  getOrders(): Observable<Order[]> {
    return this.httpClient
      .get<Order[]>(`${this.apiConfig.apiGatewayUrl}/orders`)
      .pipe(
        tap((orders: Order[]) => {
          this.setLoadedOrders(orders);
        })
      );
  }

  deleteAndReloadOrders(orderId: string): Observable<undefined> {
    return this.deleteOrder(orderId).pipe(
      tap(() => this.getOrders().subscribe())
    );
  }

  deleteOrder(orderId: string): Observable<undefined> {
    return this.httpClient
      .delete(`${this.apiConfig.apiGatewayUrl}/orders/${orderId}`)
      .pipe(map(() => undefined));
  }

  getOrderById(orderId: string): Order | undefined {
    return this.loadedOrders
      .getValue()
      ?.find((order: Order) => order.id === orderId);
  }

  batchDelete(
    orderIds: string[],
    restockSwags: boolean
  ): Observable<undefined> {
    const params: any = {ids: orderIds, restockSwags: restockSwags};

    return this.httpClient
      .post(`${this.apiConfig.apiGatewayUrl}/orders/delete`, params)
      .pipe(
        tap(() => this.getOrders().subscribe()),
        map(() => undefined)
      );
  }

  patchOrderState(
    orderId: string,
    orderState: OrderStatus
  ): Observable<undefined> {
    return this.httpClient
      .patch(`${this.apiConfig.apiGatewayUrl}/orders/${orderId}`, {
        status: orderState,
      })
      .pipe(tap(() => {
        const order = this.loadedOrders.getValue()!.find((order) => order.id === orderId)
        if (order) order.status = orderState;
        else this.getOrders();
        }),
        map(() => undefined));
  }

  setLoadedOrders(orders: Order[] | null) {
    this.loadedOrders.next(orders);
  }
}
