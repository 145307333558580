import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ColDef,
  Column,
  GridOptions,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { Router } from '@angular/router';
import { SwagService } from '../swag.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Variant } from '../../shared/models/swag/variant.model';
import { Category } from '../../shared/models/swag/category.model';
import { Swag } from '../../shared/models/swag/swag.model';
import { take } from 'rxjs/operators';
import { SwagTableService } from './swag-table.service';
import { FormControl, FormGroup } from '@angular/forms';

export interface SwagRowDataType {
  id: string;
  image: string;
  title: string;
  variants: Variant[];
  categories: Category[] | undefined;
  price: number | undefined;
}

export enum TableMode {
  VIEW,
  PACKAGE_VARIANTS,
  PACKAGE_SWAGS,
}

@Component({
  selector: 'app-swag-table',
  templateUrl: './swag-table.component.html',
  styleUrls: ['./swag-table.component.scss'],
})
export class SwagTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('tableGrid') tableGrid?: GridOptions;
  @ViewChild('swagIds') swagIds?: string[] = [];
  @Input('tableMode') tableMode: TableMode = TableMode.VIEW;
  @Input('chosenSwagIds') chosenSwagIds?: string[];
  @Input('gridGroup') gridGroup?: FormGroup;
  @Input('selectedSwag') selectedSwag?: Swag;
  @Output('addSwagRequest') addSwagRequestEvent = new EventEmitter();
  @Output('cancelSwagRequest') cancelSwagRequestEvent = new EventEmitter();
  @Output('selectPackageSwagIds') selectPackageSwagIdsEvent = new EventEmitter<
    string[]
  >();
  @Output('removePackageSwagIds') removePackageSwagIdsEvent = new EventEmitter<
    string[]
  >();
  @Output('gridValuesChanged') gridValuesChanged = new EventEmitter();

  selectedRowNode: RowNode | undefined;
  onSwagCardClosedSub$?: Subscription;
  gridLoaded = new BehaviorSubject<boolean>(false);
  swagsSub$?: Subscription;
  allowExport = true;
  columnDefs: ColDef[] = [];

  rowData: SwagRowDataType[] = [];
  rowSelected = false;

  constructor(
    private router: Router,
    private swagService: SwagService,
    private swagTableService: SwagTableService
  ) {}

  ngOnInit(): void {
    this.allowExport = this.tableMode === TableMode.VIEW;
    this.columnDefs = this.swagTableService.getColumnDefs(this.tableMode, this);
  }

  ngOnChanges() {
    if (!this.tableGrid) return;
    this.reloadSwags();
  }

  onGridReady() {
    if (this.swagsSub$) return;
    this.reloadSwags();
  }

  onFirstDataRendered() {
    this._setSelectedRowNode();
    this._setOnSwagCardClosedSub();
    this.gridLoaded.next(true);
  }

  onGridSizeChanged() {
    this.tableGrid!.api?.sizeColumnsToFit();
  }

  reloadSwags() {
    if (!this.tableGrid) return;
    if (this.swagsSub$) this.swagsSub$.unsubscribe();

    this.swagsSub$ = this.swagService.loadedSwags.subscribe((loadedSwags) => {
      const loadedSwagsCopy = JSON.parse(JSON.stringify(loadedSwags));
      let swagList: { [p: string]: Swag } | null = {};

      //Select swags to be shown in grid
      if (loadedSwagsCopy && Object.keys(loadedSwagsCopy).length > 0) {
        switch (this.tableMode) {
          case TableMode.VIEW:
            swagList = SwagTableService.generateViewSwags(loadedSwagsCopy);
            break;

          case TableMode.PACKAGE_VARIANTS:
            swagList = SwagTableService.generatePackageVariants(
              loadedSwagsCopy,
              this.chosenSwagIds
            );
            this.swagIds = this.chosenSwagIds ? [...this.chosenSwagIds] : [];
            break;

          case TableMode.PACKAGE_SWAGS:
            const swagIdsNotToDisplay = this.chosenSwagIds
              ? [...this.chosenSwagIds]
              : [];
            swagIdsNotToDisplay.push(this.selectedSwag!.id!);

            swagList = SwagTableService.generatePackageSwags(
              loadedSwagsCopy,
              swagIdsNotToDisplay
            );
            this.swagIds = this.chosenSwagIds ? [...this.chosenSwagIds] : [];
            break;
        }
      } else {
        swagList = null;
      }

      this.generateRowData(swagList);
      if (this.tableMode === TableMode.PACKAGE_VARIANTS) {
        this.createFormControls();
      }
    });
  }

  protected createKey(rowNode: RowNode, column: Column): string {
    return rowNode.data.id + column.getColDef().field!;
  }

  getContext() {
    return {
      formGroup: this.gridGroup,
      createKey: this.createKey,
    };
  }
  protected createFormControls() {
    let columns = this.tableGrid!.columnApi?.getAllColumns();
    const gridGroup = <FormGroup>this.gridGroup;
    this.tableGrid!.api?.forEachNode((rowNode: RowNode) => {
      columns
        ?.filter((column: Column) => {
          return (
            column.getColDef().field === 'variants' ||
            column.getColDef().field === 'quantity'
          );
        })
        .forEach((column: Column) => {
          const key = this.createKey(rowNode, column);
          gridGroup.addControl(key, new FormControl());
        });
    });
  }

  private _setSelectedRowNode() {
    if (this.tableMode !== TableMode.VIEW) return;
    const selectedSwag = this.swagService.selectedSwag;

    if (selectedSwag && !this.selectedRowNode) {
      this.tableGrid!.api?.forEachNode((node) => {
        if ((<Swag>node.data).id === selectedSwag.id) {
          this.selectedRowNode = node;
          this.selectedRowNode.setSelected(true);
        }
      });
    }
  }

  private _setOnSwagCardClosedSub() {
    this.onSwagCardClosedSub$ = this.swagService.onSwagCardClosed.subscribe(
      () => {
        this.selectedRowNode!.setSelected(false);
        this.selectedRowNode = undefined;
      }
    );
  }

  generateRowData(loadedSwags: { [id: string]: Swag } | null) {
    if (!loadedSwags) return;

    this.rowData = [];
    Object.values(loadedSwags).forEach((swag) => {
      let newRowData: SwagRowDataType = {
        id: swag.id!,
        title: swag.title,
        image: swag.image,
        variants: swag.variants,
        categories: swag.categories,
        price: swag.price,
      };

      this.rowData.push(newRowData);
    });

    this.tableGrid!.api?.setRowData(this.rowData);
  }

  onSelectRow(event: RowSelectedEvent) {
    if (!event.node.isSelected() || this.tableMode !== TableMode.VIEW) return;

    this.selectedRowNode = event.node;
    const id = this.selectedRowNode!.data.id;

    this.router.navigate(['/swags', { outlets: { card: [id] } }]);
  }

  getGridClasses(): string {
    switch (this.tableMode) {
      case TableMode.VIEW:
        return '';
      case TableMode.PACKAGE_VARIANTS:
        return 'grid-auto-height';
      case TableMode.PACKAGE_SWAGS:
        return 'swag-package-grid';
    }
  }

  exportAsCsv() {
    const params = {
      //Specify which columns should be exported
      columnKeys: ['title', 'variants', 'categories', 'price'],
      suppressQuotes: true,
      columnSeparator: ';',
      fileName: `CCSwags - ${new Date().toLocaleDateString()}.csv`,

      // Format csv output
      processCellCallback: (param: any) => {
        switch (param.column.colId) {
          case 'title':
            return param.value;

          case 'price':
            return param.value ? `${param.value.toFixed(2)}€` : 'k.A.';

          case 'variants':
            let variantString = '';
            let optionPresent = false;
            param.value.forEach((variant: Variant) => {
              if (variant.label)
                if (variant.label.length > 0) {
                  variantString += variant.label[0] + ' ';
                  optionPresent = true;
                }
              if (!optionPresent) variantString += 'None ';
              variantString += `(${variant.quantity}), `;
            });

            variantString = variantString.substr(0, variantString.length - 2);
            return variantString;

          case 'categories':
            if (param.value)
              if (param.value.length > 0) return param.value[0].name;
            return 'No category found';
        }
      },
    };
    this.tableGrid!.api?.exportDataAsCsv(params);
  }

  ngOnDestroy() {
    this.gridLoaded.pipe(take(1)).subscribe(() => {
      this.onSwagCardClosedSub$?.unsubscribe();
      if (this.swagsSub$) this.swagsSub$.unsubscribe();
    });
    this.tableGrid = undefined;
  }

  fireAddSwagToPackageEvent() {
    //TODO: tests
    const selectedRows = this.tableGrid!.api?.getSelectedRows();
    const swagIds = selectedRows?.map(
      (selectedRow: RowNode) => selectedRow.id!
    )!;
    this.selectPackageSwagIdsEvent.emit(swagIds);
  }

  onRowSelection() {
    const rows = this.tableGrid!.api?.getSelectedNodes();
    this.rowSelected = !!rows ? rows?.length > 0 : false;
  }

  onRemovePackageSwags() {
    //TODO: tests
    const selectedRows = this.tableGrid!.api?.getSelectedRows();
    const swagIds = selectedRows?.map(
      (selectedRow: RowNode) => selectedRow.id!
    )!;
    this.removePackageSwagIdsEvent.emit(swagIds);
    this.tableGrid!.api?.deselectAll();
  }
}
